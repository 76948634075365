
import { IOverlay, IOverlayUploadInput } from "@/interfaces/IOverlay";
import { IRestrictedArea } from "@/interfaces/IRestrictedArea";
import { OverlayService } from "@/services/OverlayService";
import { ApiService } from "@/services/ApiService";
import { RestrictedAreasService } from "@/services/RestrictedAreasService";
import { store } from "@/store";
import { Options, Vue } from "vue-class-component";
import { MapService } from "@/services/MapService";
import { NotificationService } from '@/services/NotificationService';
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import eventBus from "@/services/EventBus";
import { ContextService } from "@/services/ContextService";
import { ConfigService } from "@/services/ConfigService";
import { CustomRoadsService } from "@/services/CustomRoadsService";

@Options({
  props: {},
})
export default class UploadOverlay extends Vue {
  overlay: IOverlay = {} as IOverlay;

  display: {
    input: IOverlayUploadInput;
  } = {
    input: null,
  };
  //overlayInput: IOverlayUploadInput;

  showModal = false;
  isClickable = true;

  publishedDate = "";
  editDate = "";
  creationDate = "";
  publishedMessage = "";

  isEditor = false;

  overlayFL: FeatureLayer;
  customRoadsFL: FeatureLayer;
  restrictedAreasFL: FeatureLayer;

  publishedMessageTimeout = 20000;
  updateOverlayListTimeout = 30000;

  restrictedAreasSize = {size: 0};
  customRoadsSize = {size: 0};

  get stringOverlay() {
    if (this.display && this.display.input) {
      return JSON.stringify(this.display.input);
    } else {
      return "";
    }
  }

  mounted() {
    this.overlay = { ...store.state.overlay };
    this.overlay.PublishedDate = new Date(this.overlay.PublishedDate);
    this.publishedDate = this.overlay.PublishedDate.toLocaleString();
    if(this.publishedDate == "01/01/1970, 01:00:00") {
      this.publishedDate = "";
    }
    this.overlay.EditDate = new Date(this.overlay.EditDate);
    this.editDate = this.overlay.EditDate.toLocaleString();
    this.overlay.CreationDate = new Date(this.overlay.CreationDate);
    this.creationDate = this.overlay.CreationDate.toLocaleString();
    if(this.overlay.EditedBy == "") {
      this.overlay.EditedBy = "Anonymous";
    }
    if(this.overlay.CreatedBy == "") {
      this.overlay.CreatedBy = "Anonymous";
    }
    this.publishedMessage = this.overlay.PublishedMessage;
    this.isEditor = ContextService.getInstance().isEditor();
    this.restrictedAreasSize = RestrictedAreasService.getInstance().tableSize;
    this.customRoadsSize = CustomRoadsService.getInstance().tableSize;
    this.initFeatureLayers();
  }

  initFeatureLayers() {
    if(!this.overlayFL) {
      //this.overlayFL = MapService.getInstance().findLayerByTitle(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.title]); 
      this.overlayFL = new FeatureLayer({
        url: MapService.getInstance().findLayerByTitle(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.urlField])
        });
    }

    if(!this.customRoadsFL) {
      // Commented by SCH tu use the URL instead of the Title
      //this.customRoadsFL = MapService.getInstance().findLayerByTitle(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.customRoads.title]); 
      this.customRoadsFL = new FeatureLayer({
        url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.customRoads.urlField]
        });

    }
    if(!this.restrictedAreasFL) {
      // Commented by SCH tu use the URL instead of the Title
      //this.restrictedAreasFL = MapService.getInstance().findLayerByTitle(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.restrictedAreas.title]); 
      this.restrictedAreasFL = new FeatureLayer({
        url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.restrictedAreas.urlField]
        });
    }
  }

  refreshFeatureLayers() {
    if(this.overlayFL) {
      this.overlayFL.refresh();
    }
    if(this.customRoadsFL) {
      this.customRoadsFL.refresh();
    }
    if(this.restrictedAreasFL) {
      this.restrictedAreasFL.refresh();
    }
  }

  publishedStatus(statusNumber : string) {
    let status = "";

    if (statusNumber != null) {
      if (statusNumber === '0' || Number(statusNumber) == 0) {
        status = "Not published";
      } else if (statusNumber === '1' || Number(statusNumber) == 1) {
        status = "Pending";
      } else if (statusNumber === '2' || Number(statusNumber) == 2) {
        status = "Published";
      } else if (statusNumber === '3' || Number(statusNumber) == 3) {
        status = "Failed";
      }
    }
    return status;
  }

  async onUploadOverlay(): Promise<void> {
    eventBus().emitter.emit("isClickableEvent", { isClickable: true });
    const organizationName = this.overlay.Organisation
    const newOrganizationName = organizationName.replace(/[^a-z0-9]/gi,'');
    const eventName = this.overlay.Event
    const newEventName = eventName.replace(/[^a-z0-9]/gi,'');
    //let overlayName = ("OVERLAY-"+newOrganizationName+"-"+newEventName+"-"+this.overlay.Name+"-1").toUpperCase();
    let overlayName = ("OVERLAY-"+newOrganizationName+"-"+newEventName+"-"+this.overlay.Name).toUpperCase();
    this.overlay.PublishedStatus = 1;
    NotificationService.getInstance().showNotification("Publish overlay name : "+ overlayName,"Publication in progress", "blue", false);
    let overlayBuild;
    let overlayUp;
    try {
      overlayBuild = await OverlayService.getInstance().buildUploadOverlayInput(newEventName, this.overlay.Name, newOrganizationName);
      overlayUp = await ApiService.getInstance().uploadOverlay(this.overlay.OBJECTID, overlayBuild.organization, overlayBuild.event, this.overlay.Name, overlayBuild.overlay_spec);
    } catch(error) {
      NotificationService.getInstance().hideNotification();
      this.overlay.PublishedStatus = 3;
      this.overlay.PublishedMessage = error.data.error;
      this.publishedMessage = error.data.error;
      this.updateOverlay(this.publishedMessage);
      NotificationService.getInstance().showNotification("Publish overlay name : "+ overlayName, "Publication failed :<br/>"+error.data.error, "red", false);
      overlayName = "";
    }
    if(overlayUp && !overlayUp.data.error && overlayUp.length != 0) {
      NotificationService.getInstance().hideNotification();
      this.overlay.PublishedStatus = 2;
      NotificationService.getInstance().showNotification("Publish overlay name : "+ overlayName,"Publication successed", "green", false);
      this.refreshFeatureLayers();
      this.refreshUI(overlayUp.data[0].numberSplitOverlay);
    }
    this.isOverlayPublished(overlayName);
    this.isClickable = !this.isClickable;
    setTimeout(() => {eventBus().emitter.emit("isOverlayEvent", { isPublished: true })}, this.updateOverlayListTimeout);
    eventBus().emitter.emit("isOverlayEvent", { isPublished: false });
    eventBus().emitter.emit("isClickableEvent", { isClickable: false });
  }

  async isOverlayPublished(publishedName: string) {
    const overlayGraphic : Graphic = new Graphic({
      attributes: {
        "OBJECTID": this.overlay.OBJECTID,
        "PublishedStatus": this.overlay.PublishedStatus,
        "PublishedName": publishedName
      },
      geometry: null
    });

    await ApiService.callEsriApplyEdits(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.urlField], [overlayGraphic], ApiService.editMode.UPDATE);
  }

  refreshUI(splitNumber : string) {
    this.overlay.PublishedDate = new Date();
    this.publishedDate = this.overlay.PublishedDate.toLocaleString();
    this.overlay.EditDate = new Date();
    this.editDate = this.overlay.EditDate.toLocaleString();
    this.overlay.EditedBy = ContextService.getInstance().user.name+" "+ContextService.getInstance().user.surname;
    this.updateOverlay("");
    this.overlay.NumberSplitOverlays = splitNumber;
    this.publishedMessage = "Data is updating..."
    setTimeout(() => {this.updatePublishedMessage()}, this.publishedMessageTimeout)
  }

  async updatePublishedMessage() {
    const featureLayer = new FeatureLayer({
      url: `${ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.urlField]}?token=${ConfigService.getInstance().webmapConfig?.esritoken || ''}`
    });
    const query = featureLayer.createQuery();
    query.where = '1=1';
    query.outFields = ['*'];
    const response = await featureLayer.queryFeatures(query);
    if (response && response.features) {
      response.features.forEach(feature => {
        const overlay = feature.attributes;
        if(overlay.OBJECTID == this.overlay.OBJECTID ) {
            this.overlay.PublishedMessage = overlay.PublishedMessage;
            this.publishedMessage = this.overlay.PublishedMessage;
        }
      });
    }
  }

  async updateOverlay(error : string) {
    let overlayGraphic: Graphic;
    if(this.overlay.PublishedStatus == 3) {
      overlayGraphic = new Graphic({
        attributes: {
          "OBJECTID": this.overlay.OBJECTID,
          "PublishedMessage": error,
        }
      });
    } else {
      overlayGraphic = new Graphic({
        attributes: {
          "OBJECTID": this.overlay.OBJECTID,
          "EditedBy": this.overlay.CreatedBy,
          "NumberSplitOverlays": this.overlay.NumberSplitOverlays
        }
      });
    }


    await ApiService.callEsriApplyEdits(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.urlField], [overlayGraphic], ApiService.editMode.UPDATE);
  }


  get isDisable() {
    var clickable = false;
    if(!this.isClickable) {
      clickable = true
    } else if(this.restrictedAreasSize.size == 0 && this.customRoadsSize.size == 0) {
        clickable = true;
    } else if(this.publishedMessage == "Data is updating...") {
        clickable = true;
    }
    return clickable;
  }
}
