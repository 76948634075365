import { IConfig } from '@/interfaces/IConfig';
import { ApiService } from '@/services/ApiService';
import { ContextService } from '@/services/ContextService'
import { config } from '@/config/config'

export class ConfigService {
    private static instance: ConfigService;
    public config!: IConfig;
    public targetEnv = '';
    public webmapConfig: any;

    /**
    * Get env
    */
     private async getEnv(): Promise<void> {
        return new Promise<void>((resolve, reject) => {

            // const publicPath = ConfigService.getInstance().config.appConfig.publicPath;
            const publicPath = config.publicPath;

            ///fetch('./config/env.json')
            fetch('.' + publicPath + 'config/env.json')
                .then(res => res.json())
                .then(
                    config => {
                        ConfigService.instance.targetEnv = config && config.env ? config.env : '';
                        resolve();
                    },
                    (err: any) => {
                        reject(err);
                    }
                );
        });
    }

    /**
     * Get config service instance
     */
    public static getInstance(): ConfigService {
        if (!ConfigService.instance) {
            ConfigService.instance = new ConfigService();
            ConfigService.instance.getConfig();
        }

        return ConfigService.instance;
    }

    public static async initInstance(): Promise<void> {
        ConfigService.instance = new ConfigService();
        await ConfigService.instance.getEnv();
        await ConfigService.instance.getConfig();
        await ContextService.getInstance().loadUser();
    }

    public async initWebmapConfig(): Promise<void> {
        try {
            ConfigService.instance.webmapConfig = await ApiService.getInstance().getWebmapConfig();
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    /**
    * Get config
    */
    public async getConfig(): Promise<IConfig> {
        
        const publicPath = config.publicPath;
        const path = `.${publicPath}config/${ConfigService.instance.targetEnv}/config.json`;


        return new Promise<IConfig>((resolve, reject) => {
            fetch(path)
                .then(res => res.json())
                .then(
                    config => {
                        ConfigService.instance.config = config as IConfig;
                        resolve(config as IConfig);
                    },
                    (err: any) => {
                        reject(err);
                    }
                );
        });
    }
}