
import { MapService } from "@/services/MapService";
import { ContextService } from "@/services/ContextService";
import {store} from "@/store";
import { watch } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import { IOverlay } from "../../../../interfaces/IOverlay";
import { OverlayService } from "../../../../services/OverlayService";
import OverlayCard from "./overlay-card/OverlayCard.vue";

@Options({
  components: { OverlayCard },
  props: {},
})
export default class OverlaysList extends Vue {
  overlaysList: IOverlay[] = [];
  isEditor = false;

  async mounted() {
    await this.refresh();
    watch(this.countOverlays, () => {
      this.refresh();
    });

    this.isEditor = ContextService.getInstance().isEditor();

    if(store.state.lastOverlay != null) {
      store.commit("setOverlay", store.state.lastOverlay);
    }
  }

  get countOverlays() {
    this.refresh();
    return store.state.overlaysCounter;
  }

  async refresh() {
    this.overlaysList = await OverlayService.getInstance().getCustomOverlays();
    this.overlaysList.sort((o1: IOverlay, o2: IOverlay) => {
      return o1.Name.localeCompare(o2.Name);
    });
  }

  onNewOverlay(): void {
    MapService.getInstance().startDrawing('overlay');
  }

  /*   addNewOverlay() {

  } */
}
