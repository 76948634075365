import { IOverlay } from '@/interfaces/IOverlay'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { InjectionKey } from 'vue'
import { IUser } from '@/interfaces/IUser'

export interface IState {
    overlay: IOverlay,
    lastOverlay: IOverlay,
    overlaysCounter: { val: number };
    currentUser: IUser;
}

export const key: InjectionKey<Store<IState>> = Symbol()

export const store = createStore<IState>({
    state: {
        overlay: null,
        lastOverlay: null,
        overlaysCounter: { val: 0 },
        currentUser: null
    },
    mutations: {
        setOverlay(state, payload: IOverlay) {
            state.lastOverlay = null;
            state.overlay = payload
        },
        setApplyToOverlay(state, payload: IOverlay) {
            state.lastOverlay = payload;
            state.overlay = null;
        },
        addOverlay(state) {
            state.overlaysCounter.val++;
        },
        deleteOverlay(state) {
            state.overlaysCounter.val--
        },
        removeOverlay(state) {
            state.overlay = null
        },
        setUser(state, user: IUser) {
            state.currentUser = user;
        }
    },
    getters: {
        currentUser(): IUser {
            return store.state.currentUser
        }
    },
    actions: {
    },
    modules: {
    }
})

export function useStore() {
    return baseUseStore(key)
}
