
import "@esri/calcite-components";
export class LoaderService {

    private static instance: LoaderService;

    private loaderContainer: HTMLDivElement;
    private readonly loaderId = 'app-spinner';


    private constructor() {
        this.loaderContainer = document.createElement('div');
        this.loaderContainer.id = this.loaderId;
        this.loaderContainer.className = 'app-spinner';
        const loader = document.createElement('calcite-loader');
        loader.active = true;
        this.loaderContainer.appendChild(loader);
    }

    static getInstance() {
        if (!LoaderService.instance) {
            LoaderService.instance = new LoaderService();
        }
        return LoaderService.instance;
    }

    public showLoader(): void {
        const loader = document.getElementById(this.loaderContainer.id);
        if (!loader) {
            document.body.appendChild(this.loaderContainer);
        }
    }

    public hideLoader(): void {
        const loader = document.getElementById(this.loaderContainer.id);
        if (loader) {
            document.body.removeChild(loader);
        }
    }
}