import { Constants } from "@/services/Constants";

export function standardizeErrorMsg(errorMsg: string, appendErrMsg: string): any {
    errorMsg = errorMsg ? errorMsg + Constants.strReturnLine + appendErrMsg : appendErrMsg;
    return errorMsg;
  }

  export function standardizeCatchErrorMsg(error: any): any {
    let errorMsg = Constants.errorMsgUnknownError;
    errorMsg = error ? error.data ? error.data.error ? error.data.error : errorMsg : errorMsg: errorMsg;

    return errorMsg;
  }