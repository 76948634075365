
import { Options, Vue } from "vue-class-component";
import { ApiService } from "@/services/ApiService";
import { ContextService } from "@/services/ContextService";
import { LoaderService } from "@/services/LoaderService";


@Options({
  props: {},
})
export default class Auth extends Vue {
  private loginFailed = false;
  private login = '';
  private pass = '';

  mounted(): void {
    LoaderService.getInstance().hideLoader();
  }

  private async doLogin(): Promise<any> {
    
    try {
      ContextService.getInstance().user = await ApiService.getInstance().login(this.login, this.pass);
      this.loginFailed = false;
      this.$emit('loginSucceed');
      
    } catch (error) {
      this.loginFailed = true;

      setTimeout(() => {
        this.loginFailed = false;
      }, 3000);
    }
  }
}
