import esriConfig from "@arcgis/core/config";
import { ConfigService } from "./ConfigService";
import axios, {
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
} from "axios";
import { ContextService } from "./ContextService";
import { IUserPermission } from "@/interfaces/IUserPermission";
import { RevGeocode } from "@/interfaces/IRevGeocode";
import { IApiConfig } from "@/interfaces/IApiConfig";

export class ApiService {
  private static instance: ApiService;

  private vehicleTypes: any;
  private dayLabels: any;
  private osl: any;
  private clients: any;
  private events: any;
  private currentOrg: any;
  private currentEvent: any;

  public static editMode: { ADD: string; UPDATE: string; DELETE: string } = {
    ADD: "add",
    UPDATE: "update",
    DELETE: "delete",
  };

  constructor() {
    esriConfig.portalUrl = ConfigService.getInstance().config.portal.url;
  }

  /**
   * Create user
   * @param data
   * @returns
   */
  private static createUser(result: any): IUserPermission {
    const out: IUserPermission = {
      empty_response: true,
      user_found: false,
      is_editor: false,
      name: "",
      surname: "",
    };

    if (result) {
      out.user_found = true;
      out.empty_response = false;

      if (result.data.role && [1, 2].indexOf(result.data.role) !== -1) {
        out.is_editor = true;
      }
    }

    return out;
  }

  static getInstance() {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
      axios.defaults.withCredentials = true;
    }

    return ApiService.instance;
  }

  /**
   * Login
   * @param login
   * @param pass
   * @param token
   * @returns
   */
  async login(login: string, pass: string, token = ""): Promise<any> {
    try {
      const out: IUserPermission = {
        empty_response: true,
        user_found: false,
        is_editor: false,
        name: "",
        surname: "",
      };

      let data: any = {};

      if (token) {
        data = { token: token };
      } else {
        data = { login: login, pass: pass };
      }

      const result = await axios.post(
        ApiService.makeAPIUrl(ConfigService.getInstance().config.api.loginPath),
        data
      );

      return ApiService.createUser(result);
    } catch (error) {
      ApiService.getInstance().logout();
      return Promise.reject(error.response);
    }
  }

  /**
   * Get current user
   */
  async getCurrentUser(): Promise<any> {
    try {
      const result = await axios.get(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.currentUserPath
        )
      );
      return ApiService.createUser(result);
    } catch (error) {
      return Promise.resolve();
    }
  }

  /**
   * Logout
   * @returns
   */
  async logout(): Promise<any> {
    try {
      const result = await axios.get(
        ApiService.makeAPIUrl(ConfigService.getInstance().config.api.logoutPath)
      );
      return result;
    } catch (error) {
      return error.response;
    }
  }

  /**
   * Make api URL
   * @param path
   */
  private static makeAPIUrl(path: string, origin?: string): string {
    let urlProxy = `${ConfigService.getInstance().config.api.baseUrl}${path}`;
    urlProxy = urlProxy.replace("{organisation_id}", ContextService.getInstance().orgId)
    urlProxy = urlProxy.replace("{event_id}", ContextService.getInstance().eventId)
    return urlProxy; //${ConfigService.getInstance().config.api.apiKey}`;
  }

  /**
   * Geocode URL
   * @param search
   * @param maxResults
   * @param lang
   * @returns
   */
  async geocode(search: string, maxResults = 1, lang = "en"): Promise<any> {
    try {
      const result = await axios.post(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.geocodePath
        ),
        {
          text: search,
          lang: lang,
          maxresults: maxResults,
        }
      );
      return result;
    } catch (error) {
      return error.response;
    }
  }

  /**
   *
   * @param search
   * @param maxResults
   * @param lang
   * @returns
   */
  async suggest(
    search: string,
    lat: number,
    lng: number,
    maxResults = 1,
    lang = "en",
    cancelToken: CancelTokenSource = null
  ): Promise<any> {
    try {
      const options: AxiosRequestConfig = {
        method: "POST",
        url: ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.suggestPath
        ),
        data: {
          text: search,
          lang: lang,
          maxresults: maxResults,
          at: { lat: lat, lng: lng },
        },
      };
      if (cancelToken) {
        options.cancelToken = cancelToken.token;
      }

      const result = await axios.post(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.suggestPath
        ),
        {
          text: search,
          lang: lang,
          maxresults: maxResults,
          at: { lat: lat, lng: lng },
        }
      );
      return result;
    } catch (error) {
      return error.response;
    }
  }

  /**
   * Reverse geocode
   * @param lat
   * @param lng
   * @param lang
   * @returns
   */
  async revGeocode(lat: number, lng: number, lang = "en"): Promise<any> {
    try {
      const result: RevGeocode = await axios.post(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.revGeocodePath
        ),
        {
          lat: lat,
          lng: lng,
          lang: lang,
        }
      );
      return result;
    } catch (error) {
      return error.response;
    }
  }

  /**
   * Calculate route
   * @param fromPoint
   * @param toPoint
   * @param mode
   * @param traffic
   * @param overlays
   * @param departure
   * @param arrival
   * @param via
   * @returns
   */
  async route(
    fromPoint: any,
    toPoint: any,
    mode: string,
    traffic: string,
    overlays: string,
    useTravelTimeService: boolean,
    departure: string,
    arrival: string,
    traverseGates: boolean,
    isV8 = true,
    viaRoute: any[],
    usePrecomputedRoute: boolean,
    avoid: any[]
  ): Promise<any> {
    try {
      let version = null;
      if (isV8) {
        version = "v8";
      }
      else {
        version = "v7";
      }

      const result = await axios.post(
        ApiService.makeAPIUrl(ConfigService.getInstance().config.api.routePath),
        {
          mode: "fastest;" + mode + ";traffic:" + traffic,
          from: fromPoint,
          to: toPoint,
          via: viaRoute,
          departure: departure,
          arrival: arrival,
          traverseGates: traverseGates,
          version: version,
          usehistoricaltraveltime: usePrecomputedRoute,
          usetraveltimeestimateservice: useTravelTimeService,
          overlays: overlays,
          avoid: avoid,
          geom: true,
          details: true
        }
      );
      return result;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Get all overlays
   * @returns
   *
   */
  async getAllOverlays(): Promise<any> {
    const result = await axios.get(
      ApiService.makeAPIUrl(
        ConfigService.getInstance().config.api.overlaysAllPath
      )
    );
    const overlays = result.data;
    return overlays;
  }

  /**
   * Get custom overlays
   * @param vehicleTypes
   * @param dayLabels
   * @param osl
   * @returns
   */
  async getCustomOverlays(
    vehicleTypes: any,
    dayLabels: any,
    osl: any,
    organization: string,
    event: string
  ): Promise<any> {
    try {
      const result = await axios.post(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.customOverlaysPath
        ),
        {
          vehicleTypes: vehicleTypes,
          dayLabels: dayLabels,
          osl: osl,
          organization: organization,
          event: event
        }
      );
      return result;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Upload an overlay
   * @param objectId
   * @param organization
   * @param event
   * @param name
   * @param overlay_spec
   * @returns
   */
  async uploadOverlay(
    objectId: number,
    organization: string,
    event: string,
    name: string,
    overlay_spec: any
  ): Promise<any> {
    try {
      const result = await axios.post(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.uploadOverlayPath
        ),
        {
          objectId: objectId,
          organization: organization,
          event: event,
          name: name,
          overlay_spec: overlay_spec,
        }
      );
      return result;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Delete an overlay
   * @param overlayName
   * @param objectId
   * @returns
   */
  async deleteOverlay(overlayName: string, objectId: number): Promise<any> {
    try {
      const result = await axios.post(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.deleteOverlayPath
        ),
        {
          map_name: overlayName,
          objectId: objectId,
        }
      );
      return result;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Search ovelays
   * @param overlayName
   * @returns
   */
  async searchOverlay(overlayName: string): Promise<any> {
    try {
      const result = await axios.post(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.searchOverlayPath
        ),
        {
          map_name: overlayName,
          geom: "full",
          acceptMissingLayers: "true",
          layer_id: "LINK_ATTRIBUTE_FC1",
          format: "geojson",
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Get events
   * @returns
   */
  async getEvents(): Promise<any> {
    try {
      if (this.events) {
        return this.events;
      }
      const result = await axios.get(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.proxyEventsPath
        )
      );
      const formatted: any[] = [];
      if (result && result.data.entities && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      this.events = formatted;
      return this.events;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**Get vehicle types
   *
   * @returns
   */
  async getVehicleTypes(): Promise<any> {
    try {
      if (this.vehicleTypes) {
        return this.vehicleTypes;
      }

      const result = await axios.get(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.proxyVehicleTypesPath
        )
      );
      const formatted: any[] = [];
      if (result && result.data.entities && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      this.vehicleTypes = formatted;
      return this.vehicleTypes;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Get day labels
   * @returns
   */
  async getDayLabels(): Promise<any> {
    try {
      if (this.dayLabels) {
        return this.dayLabels;
      }
      const result = await axios.get(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.proxyDayLabelsPath
        )
      );
      const formatted: any[] = [];
      if (result && result.data.entities && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      this.dayLabels = formatted;
      return this.dayLabels;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Get osl
   * @returns
   */
  async getOsl(): Promise<any> {
    try {
      if (this.osl) {
        return this.osl;
      }

      const result = await axios.get(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.proxyOslPath
        )
      );
      const formatted: any[] = [];
      if (result && result.data.entities && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      this.osl = formatted;
      return this.osl;
    } catch (error) {
      return this.osl;
    }
  }

  /**
   * Get client
   * @returns
   */
  async getClients() {
    try {
      if (this.clients) {
        return this.clients;
      }
      const result = await axios.get(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.proxyClientPath
        )
      );
      const formatted: any[] = [];
      if (result && result.data.entities && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      this.clients = formatted;
      return this.clients;
    } catch (error) {
      return this.clients;
    }
  }

  /**
   * Get current permission user (by Id)
   * @returns
   */
  async getCurrentPermissionUser(userId: string): Promise<any> {
    const out = {
      name: "",
      surname: "",
    };

    try {
      const url = `${ConfigService.getInstance().config.api.baseUrl}${ConfigService.getInstance().config.api.proxyPermissionPath
        }`
        .replace("{event_id}", ContextService.getInstance().eventId)
        .replace("{organisation_id}", ContextService.getInstance().orgId)
        .replace("{user_id}", ContextService.getInstance().userId);
      const result = await axios({
        method: "get",
        url: url,
      });

      if (
        result &&
        result.data &&
        result.data.result &&
        result.data.result.items &&
        result.data.result.items.length
      ) {
        const itemId: string = result.data.result.items[0];

        if (itemId && result.data.entities && result.data.entities.items) {
          const item: any = result.data.entities.items[itemId];

          /*if(item) {
                        out.user_found = true;
                        out.empty_response = false;

                        if(item.conditions && item.conditions.editor) {
                            out.is_editor = true;
                        }
                    }*/

          const user: any =
            result.data.entities.users && result.data.entities.users[userId]
              ? result.data.entities.users[userId]
              : null;

          if (user) {
            out.name = user.name;
            out.surname = user.surname;
          }
        }
      }

      return out;
    } catch (error) {
      return out;
    }
  }

  /**
   * Get current organizaion
   * @returns
   */
  async getCurrentOrg(): Promise<any> {
    try {
      if (this.currentOrg) {
        return this.currentOrg;
      }

      const result = await axios.get(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.proxyCurrentOrgPath
        )
      );
      this.currentOrg = result.data;
      return Promise.resolve(this.currentOrg);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Get current event
   * @returns
   */
  async getCurrentEvent(): Promise<any> {
    try {
      if (this.currentEvent) {
        return this.currentEvent;
      }
      await ApiService.getInstance().getEvents();
      this.currentEvent = this.events.find(
        (ev: any) => ev.id === ContextService.getInstance().eventId
      );
      return this.currentEvent;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Get webmap config
   * @returns
   */
  async getWebmapConfig(): Promise<any> {
    try {
      const res = await axios.post(
        ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.webmapConfigPath
        ),
        {
          organization: ContextService.getInstance().orgId,
          event: ContextService.getInstance().eventId,
          uimodule: 2,
        }
      );
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * Login to front end api
   * @param email
   * @param pass
   * @returns
   */
  /*
    Obsolete. Commented by SCH - 05.11.21
    async frontLogin(email: string, pass: string): Promise<any> {
        axios.defaults.withCredentials = true;
        try {
            const result = await axios({
                method: 'post',
                url: ConfigService.getInstance().config.api.frontUrl + ConfigService.getInstance().config.api.frontLoginPath,
                data: {
                    user: {
                        email: email,
                        password: pass
                    }
                },
                headers: {
                    xsrfCookieName: "CSRF-TOKEN",
                    xsrfHeaderName: "X-CSRF-Token",

                }
            });
            return result;
        } catch (error: any) {
            return Promise.reject(error.response);
        }
    }*/

  /**
   * Call Esri apply edits
   * @param featureLayerUrl
   * @param features
   * @param mode
   * @returns
   */
  public static async callEsriApplyEdits(
    featureLayerUrl: string,
    features: any[],
    mode: string
  ): Promise<any[]> {
    try {
      const result: any = await axios({
        method: "post",
        url: ApiService.makeAPIUrl(
          ConfigService.getInstance().config.api.applyEditsPath
        ),
        data: {
          url: featureLayerUrl,
          features: features,
          mode: mode,
        },
      });

      return result.data && result.data.length ? result.data : null;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Call Esri query layer
   * @param url
   * @param query
   * @returns
   */
  /*public static async callGetFeatures(featureLayerUrl: string, query: Query): Promise<any> {        
       try {
           const result:any = await axios({
               method: 'post',
               url: ApiService.makeAPIUrl('esri/queryLayer?apiKey='),
               data: {
                   url: featureLayerUrl,
                   query: query
               }
           });

           return result.data;
       } catch(error: any) {
           return Promise.reject(error);
       }
   }*/

  /**
   *
   * @param organizationId
   * @param EventId
   * @param searchTerm
   * @param boundingBox
   */
  public async getPois(
    organizationId: string,
    EventId: string,
    searchTerm: string,
    boundingBox: number[],
    cancelSource?: CancelTokenSource
  ): Promise<any> {
    try {
      const body = {
        organization: organizationId,
        event: EventId,
        name: searchTerm,
        types: [] as any[],
        layers: [] as any[],
        boundingBox: boundingBox,
      };

      const options: AxiosRequestConfig = {
        method: "post",
        url: ApiService.makeAPIUrl(ConfigService.getInstance().config.api.pois),
        data: body,
      };

      if (cancelSource) {
        options.cancelToken = cancelSource.token;
      }

      const res = await axios(options);
      return res.data;
    } catch (e) {
      console.error(e);
    }
  }

  /*public async getLocationTypes(): Promise<any[]> {
    try {
      const url = `${ConfigService.getInstance().config.api.baseUrl}${ConfigService.getInstance().config.api.locationTypesPath
        }`
        .replace("{organisation_id}", ContextService.getInstance().orgId)
        .replace("{event_id}", ContextService.getInstance().eventId);
      const result = await axios({
        method: "get",
        url: ApiService.makeAPIUrl(ConfigService.getInstance().config.api.locationTypesPath),
      });
      const formatted: any[] = [];
      if (result && result.data) {
        for (const i in result.data) {
          formatted.push(result.data[i]);
        }
      }
      return formatted;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }*/

  public async getProxyLocationTypes(): Promise<any[]> {
    try {
      const result = await axios({
        method: "get",
        url: ApiService.makeAPIUrl(ConfigService.getInstance().config.api.proxyLocationTypesPath),
      });
      const formatted: any[] = [];
      if (result && result.data && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      return formatted;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  public async getProxyEventLocations(): Promise<any[]> {
    try {
      
      const configAPI = ConfigService.getInstance().config.api as IApiConfig;

      const result = await axios({
        method: "get",
        url: ApiService.makeAPIUrl(configAPI.proxyEventLocationsPath),
      });
      const formatted: any[] = [];
      if (result && result.data && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      return formatted;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  public async getProxyTransportationPoints(): Promise<any[]> {
    try {

      console.log("getProxyTransportationPoints - proxyTransportationPointsPath: ", ConfigService.getInstance().config.api.proxyTransportationPointsPath)

      const result = await axios({
        method: "get",
        url: ApiService.makeAPIUrl(ConfigService.getInstance().config.api.proxyTransportationPointsPath),
      });
      const formatted: any[] = [];
      if (result && result.data && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      return formatted;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  public async getProxyClusters(): Promise<any> {
    try {
      const result = await axios({
        method: "get",
        url: ApiService.makeAPIUrl(ConfigService.getInstance().config.api.proxyClustersPath),
      });
      const formatted: any[] = [];
      if (result && result.data && result.data.entities.items) {
        for (const i in result.data.entities.items) {
          formatted.push(result.data.entities.items[i]);
        }
      }
      return formatted;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

}
