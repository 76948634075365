import Point from "@arcgis/core/geometry/Point";
import Polyline from "@arcgis/core/geometry/Polyline";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { MapService } from "./MapService";
import { IEventLocation } from "@/interfaces/IEventLocation";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import { ILatLng } from "@/interfaces/IVia";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import { ViaR } from "@/model/viaR";

export class MapForMatrixService {
  mapService: MapService;
  graphicLayerPoints: GraphicsLayer = null;
  graphicLayerViaPoints: GraphicsLayer = null;
  graphicLayerRoutes: GraphicsLayer = null;
  matrixGroupLayer: GroupLayer = null;
  fromGraphic: Graphic;
  toGraphic: Graphic;
  graphicRoutes: Graphic[];
  graphicId;
  colors: string[];
  viaPointsMapForMatrixService: ViaR[] = [];

  constructor(mapService: MapService) {
    this.mapService = mapService;
    //this.createGraphicLayer();
    this.graphicRoutes = [];
    this.graphicId = -1;
    this.setColors();
  }

  private setColors(): void {
    //extract from colors ramp esri
    this.colors = [
      "#d92b30",
      "#0095ba",
      "#3cccb4",
      "#ab52b3",
      "#ffb259",
      "#ffdf3c",
      "#eb82eb",
      "#c27c30",
      "#a0d17d",
      "#f260a1",
    ];
  }

  public createGraphicLayer(): void {
    if (this.matrixGroupLayer === null) {
      this.graphicLayerPoints = new GraphicsLayer({
        title: "Origins Destinations",
      });
      this.graphicLayerViaPoints = new GraphicsLayer({
        title: "Via Points",
      });
      this.graphicLayerRoutes = new GraphicsLayer({
        title: "Routes",
      });

      this.initPoints();

      this.matrixGroupLayer = new GroupLayer({
        title: "Travel time matrix",
        visible: true,
        visibilityMode: "independent",
        layers: [
          this.graphicLayerRoutes,
          this.graphicLayerViaPoints,
          this.graphicLayerPoints,
        ],
      });

      this.mapService.map.add(
        this.matrixGroupLayer,
        this.mapService.map.layers.length
      ); // adds the layer to the map
    }
  }

  private initPoints() {
    // from
    this.fromGraphic = new Graphic({
      symbol: this.getMarkerFrom(),
    });

    this.toGraphic = new Graphic({
      symbol: this.getMarkerTo(),
    });
    this.graphicLayerPoints.addMany([this.fromGraphic, this.toGraphic]);
  }

  public createOrMovePoint(
    fromOrTo: string,
    fromIdSelected: IEventLocation
  ): void {
    // First create a point geometry
    const point = new Point({
      longitude: fromIdSelected.geometry.x,
      latitude: fromIdSelected.geometry.y,
    });

    if (fromOrTo === "from") {
      this.fromGraphic.geometry = point;
    } else {
      this.toGraphic.geometry = point;
    }
  }

  public drawRoute(geometry: any): Graphic {
    const polyline = new Polyline({
      paths: geometry.paths,
      spatialReference: { wkid: 4326 },
    });

    this.graphicId = this.graphicId + 1;
    const route = new Graphic({
      symbol: this.getLineSymbol(this.graphicRoutes.length),
      geometry: polyline,
    });
    route.setAttribute("fifaId", this.graphicId);
    this.graphicRoutes.push(route);

    this.graphicLayerRoutes.add(route);
    // this.sortRoutesGraphics();
    return route;
  }

  public removeGraphic(fifaId: number): void {
    const finded = this.graphicRoutes.find(
      (myGraphic) => myGraphic.getAttribute("fifaId") === fifaId
    );
    this.graphicLayerRoutes.remove(finded);
  }

  public addGraphic(fifaId: number): void {
    const finded = this.graphicRoutes.find(
      (myGraphic) => myGraphic.getAttribute("fifaId") === fifaId
    );

    this.graphicLayerRoutes.add(finded);
  }

  private getMarkerFrom(): PictureMarkerSymbol {
    const marker = new PictureMarkerSymbol({
      url: "https://static.arcgis.com/images/Symbols/Basic/GreenStickpin.png",
      width: "30px",
      height: "30px",
    });
    return marker;
  }

  private getMarkerTo(): PictureMarkerSymbol {
    const marker = new PictureMarkerSymbol({
      url: "https://static.arcgis.com/images/Symbols/Basic/RedStickpin.png",
      width: "30px",
      height: "30px",
    });
    return marker;
  }

  private getLineSymbol(index: number) {
    // over the ramp select randomly into the ramp
    if (index > this.colors.length) {
      const max = this.colors.length;
      const min = 0;
      index = Math.floor(Math.random() * (max - min) + min);
    }

    const line = new SimpleLineSymbol({
      cap: "round",
      join: "round",
      width: 3.75,
      color: this.colors[index],
    });
    return line;
  }

  // Run it when selected route has changed
  public razRoutes(): void {
    if (this.graphicLayerRoutes) {
      this.viaPointsMapForMatrixService  = [];
      this.graphicLayerRoutes.removeAll();
      this.graphicRoutes = [];
      this.graphicId = -1;
    }
  }

  public razViaPoints(): void {
    if (this.graphicLayerViaPoints) {
      this.graphicLayerViaPoints.removeAll();
    }
  }

  public razFromToPoints(): void {
    if (this.fromGraphic)
      this.fromGraphic.geometry = null;
    if (this.toGraphic) 
      this.toGraphic.geometry = null;
  }

  cleanGroupLayerMatrix() {
    if (this.matrixGroupLayer) {
      this.mapService.map.layers.remove(this.matrixGroupLayer);
      this.matrixGroupLayer = null;
    }
  }
}
