
import { Options, Vue } from "vue-class-component";
import Header from "./components/header/Header.vue";
import Auth from "./components/auth/Auth.vue";
import MapContainer from "./components/map-container/MapContainer.vue";
import RouteWidget from "./components/widgets/route/RouteWidget.vue";
import OverlayEdition from "./components/widgets/overlays/overlay/overlay-edition/OverlayEdition.vue";
import Matrix from "./components/widgets/matrix/Matrix.vue";
import { ConfigService } from "./services/ConfigService";
import { PortalService } from "./services/PortalService";
import { ContextService } from "@/services/ContextService";
import { ApiService } from "@/services/ApiService";
import { LoaderService } from "./services/LoaderService";
import { NotificationService } from "@/services/NotificationService";
import eventBus from "@/services/EventBus";

@Options({
  components: {
    Header,
    Auth,
    RouteWidget,
    MapContainer,
    OverlayEdition,
    // Matrix
  },
})
export default class App extends Vue {
  configLoaded = false;
  mapContainerMounted = false;
  showUnauthorized = false;
  contextLoaded = false;

  onMapContainerMounted() {
    this.mapContainerMounted = true;
  }

  mounted() {
    LoaderService.getInstance().showLoader();
  }

  async beforeCreate() {
    //Context
    try {
      await ConfigService.initInstance();
      await ConfigService.getInstance().initWebmapConfig();

      this.configLoaded = true;
      this.contextLoaded = true;
      PortalService.getInstance();

      if (ContextService.getInstance().token) {
        this.showUnauthorized = !ContextService.getInstance().isAuthorized();
        
        const isEditor = ContextService.getInstance().isEditor();
        eventBus().emitter.emit("isEditorEvent", {
            isEditor,
          });

        LoaderService.getInstance().hideLoader();
      } else {
        const user = await ApiService.getInstance().getCurrentUser();
        if (user) {
          this.onLoginSucceed();
        }
      }

    } catch (error: any) {
      this.showUnauthorized = true;
      console.error(error);
      let msg =
        "An error occured while logging the user. {1}. Please contact your administrator";

      if (error && error.data && error.data.error) {
        msg = msg.replace("{1}. ", error.data.error);
      }

      if (
        !ConfigService.getInstance().webmapConfig ||
        ConfigService.getInstance().webmapConfig?.esritoken
      ) {
        NotificationService.getInstance().showNotification(
          "Error",
          msg,
          "red",
          false
        );
      }
    }
  }

  async onLoginSucceed() {
    const res = await ConfigService.getInstance().initWebmapConfig();
    
    this.showUnauthorized = false;
    this.configLoaded = true;
    this.contextLoaded = true;
    PortalService.getInstance();

    const isEditor = ContextService.getInstance().isEditor();
    eventBus().emitter.emit("isEditorEvent", {
        isEditor,
      });
  }
}
