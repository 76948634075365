import {
  IOverlay,
  IOverlaySpecInput,
  IOverlayUploadInput,
} from "@/interfaces/IOverlay";
import esriConfig from "@arcgis/core/config";
import { ConfigService } from "./ConfigService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import { RestrictedAreasService } from "./RestrictedAreasService";
import { IRestrictedArea } from "@/interfaces/IRestrictedArea";
import EsriGeoJSONUtils from "@/utils/EsriGeoJSONUtils";
import { ICustomRoads } from "@/interfaces/ICustomRoads";
import { CustomRoadsService } from "./CustomRoadsService";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Polyline from "@arcgis/core/geometry/Polyline";
import { ApiService } from "@/services/ApiService";
import { ContextService } from "./ContextService";

export class OverlayService {
  private static instance: OverlayService;

  constructor() {
    esriConfig.portalUrl = ConfigService.getInstance().config.portal.url;
  }

  static getInstance() {
    if (!OverlayService.instance) {
      OverlayService.instance = new OverlayService();
    }

    return OverlayService.instance;
  }

  // Get overlays

  async getOverlays(): Promise<any> {
    const overlays: IOverlay[] = [];
    const featureLayer = new FeatureLayer({
      url: `${ConfigService.getInstance().webmapConfig.attributes[
        ConfigService.getInstance().config.featuresServer.overlay.urlField
        ]
        }?token=${ConfigService.getInstance().webmapConfig?.esritoken || ""}`,
    });
    const query = featureLayer.createQuery();
    query.where = "1=1";
    query.outFields = ["*"];
    const response = await featureLayer.queryFeatures(query);

    if (response && response.features) {
      response.features.forEach((feature) => {
        const overlay = feature.attributes;
        overlay.geometry = feature.geometry;
        overlays.push(overlay);
      });
    }
    return overlays;
    //const overlays: IOverlay;
  }

  async getCustomOverlays(): Promise<any> {
    const overlays: IOverlay[] = [];

    const orga = await ApiService.getInstance().getCurrentOrg();
    const event = await ApiService.getInstance().getCurrentEvent();

    if (orga && orga.id && event && event.id) {
      const featureLayer = new FeatureLayer({
        url: `${ConfigService.getInstance().webmapConfig.attributes[
          ConfigService.getInstance().config.featuresServer.overlay.urlField
          ]
          }?token=${ConfigService.getInstance().webmapConfig?.esritoken || ""}`,
      });
      const query = featureLayer.createQuery();
      query.where = "1=1";
      query.outFields = ["*"];
      const response = await featureLayer.queryFeatures(query);
      if (response && response.features) {
        response.features.forEach(async (feature) => {
          const overlay = feature.attributes;
          if (
            overlay.OrganisationID == orga.id &&
            overlay.EventID == event.id
          ) {
            overlay.geometry = feature.geometry;
            overlays.push(overlay);
          }
        });
      }
    }
    return overlays;
  }

  async addOverlay(overlay: IOverlay): Promise<{ objectId: number }> {
    const geometry = overlay.geometry.clone();
    delete overlay.geometry;
    const overlayGraphic: Graphic = new Graphic({
      attributes: overlay,
      geometry: geometry,
    });

    const result: any = await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.overlay.urlField
      ],
      [overlayGraphic],
      ApiService.editMode.ADD
    );
    return result && result.length ? result[0].objectId : null;
  }

  async deleteOverlay(
    overlayName: string,
    overlayObjectId: number
  ): Promise<boolean> {
    await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.overlay.urlField
      ],
      [overlayObjectId],
      ApiService.editMode.DELETE
    );


    const result2 = await ApiService.getInstance().searchOverlay(
      overlayName + "-1"
    );
    if (result2 == true) {
      await ApiService.getInstance().deleteOverlay(
        overlayName + "-1",
        overlayObjectId
      );
    }

    /*
    const result2 = await ApiService.getInstance().searchOverlay(
      overlayName
    );

    if (result2 == true) {
      await ApiService.getInstance().deleteOverlay(
        overlayName,
        overlayObjectId
      );
    }*/
    return true;
  }

  // TASK 422: Upload : les coordonnées des areas ou custom son incorrectes pas en ( # WGS84)
  async buildUploadOverlayInput(
    event: string,
    name: string,
    organization: string
  ): Promise<IOverlayUploadInput> {
    const areas: IRestrictedArea[] = await RestrictedAreasService.getInstance().getCurrentRestrictedAreas();
    const overlaysSpec: IOverlaySpecInput[] = [];
    areas.forEach((area) => {
      const vehicleType = this.returnVehiculeType(
        area.Car,
        area.Bus,
        area.Truck,
        area.Pedestrian
      );
      // TACHE 514:
      // TODO: convertir le polygone en polyline fermée
      overlaysSpec.push({
        op: "areaoverride",
        shape: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: EsriGeoJSONUtils.arcgisToGeoJSON(
                OverlayService.projectGeomToPolyline(area.geometry)
              ),
            },
          ],
        },
        data: {
          VEHICLE_TYPES: vehicleType,
        },
        layer: "LINK_ATTRIBUTE_FCN",
      });
      /* GeojsonUtils.convertEsriToGeo(area.geometry, (err: any, result: any) => {
                
            }) */
    });

    const customRoads: ICustomRoads[] = await CustomRoadsService.getInstance().getCurrentCustomRoads();
    customRoads.forEach((customRoad) => {
      const vehicleType = this.returnVehiculeType(
        customRoad.Car,
        customRoad.Bus,
        customRoad.Truck,
        customRoad.Pedestrian
      );

      //  The type of operation depends on the field value customRoad.TypeOfEdit '1' = Creation, '2' = "Modification", '3' = 'Preferred'
      if (customRoad.TypeOfEdit == "1") {
        // create
        overlaysSpec.push({
          op: "create",
          shape: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: EsriGeoJSONUtils.arcgisToGeoJSON(
                  OverlayService.projectGeomToPolyline(customRoad.geometry)
                ),
              },
            ],
          },
          data: {
            VEHICLE_TYPES: vehicleType,
            TRAVEL_DIRECTION: customRoad.DirectionofTravel,
            SPEED_CATEGORY: customRoad.SpeedCategory,
          },
          layer: "LINK_ATTRIBUTE_FCN",
        });
      }
      if (customRoad.TypeOfEdit == "2") {
        //Modification
        overlaysSpec.push({
          op: "override",
          shape: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: EsriGeoJSONUtils.arcgisToGeoJSON(
                  OverlayService.projectGeomToPolyline(customRoad.geometry)
                ),
              },
            ],
          },
          data: {
            VEHICLE_TYPES: vehicleType,
            TRAVEL_DIRECTION: customRoad.DirectionofTravel,
          },
          layer: "LINK_ATTRIBUTE_FCN",
        });
      }
      // TACHE 514:
      // TODO rajouter la clé "type":"preferred" après data
      if (customRoad.TypeOfEdit == "3") {
        //Preferred
        overlaysSpec.push({
          op: "restrict",
          shape: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: EsriGeoJSONUtils.arcgisToGeoJSON(
                  OverlayService.projectGeomToPolyline(customRoad.geometry)
                ),
              },
            ],
          },
          data: {
            VEHICLE_TYPES: "145",
            PREFERRED_ROUTE_TYPE: "201",
            ENTRY_PENALTY: -1,
            DRIVE_PENALTY: -0.1,
          },
          layer: "LINK_ATTRIBUTE_FCN",
          type: "preferred",
        });
      }
      /* GeojsonUtils.convertEsriToGeo(area.geometry, (err: any, result: any) => {
                
            }) */
    });

    return {
      event,
      name,
      organization,
      overlay_spec: overlaysSpec,
    };
  }

  /**
   * Project geometry and convert it to a polyline
   * @param geometry
   */
  private static projectGeomToPolyline(geometry: any): any {
    const projGeom = !geometry.spatialReference.isGeographic
      ? webMercatorUtils.webMercatorToGeographic(geometry)
      : geometry;
    return geometry.type === "polyline"
      ? projGeom
      : new Polyline({
        paths: (projGeom as any).rings,
        spatialReference: { wkid: (projGeom as any).spatialReference },
      });
  }

  private returnVehiculeType(
    car: string,
    bus: string,
    truck: string,
    pedestrian: string
  ) {
    let formattedVehiculeType = "";
    if (car == "N" && bus == "N" && truck == "N" && pedestrian == "N") {
      formattedVehiculeType = "0"; // all forbidden tranport mode
    } else {
      if (car == "Y") {
        formattedVehiculeType = "car";
      }
      if (bus == "Y") {
        if (formattedVehiculeType.length > 0) {
          formattedVehiculeType += ",bus";
        } else formattedVehiculeType += "bus";
      }
      if (truck == "Y") {
        if (formattedVehiculeType.length > 0) {
          formattedVehiculeType += ",truck";
        } else formattedVehiculeType += "truck";
      }
      if (pedestrian == "Y") {
        if (formattedVehiculeType.length > 0) {
          formattedVehiculeType += ",pedestrian";
        } else formattedVehiculeType += "pedestrian";
      }
    }
    return formattedVehiculeType;
  }
}
