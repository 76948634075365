
import { IOverlay } from "@/interfaces/IOverlay";
import { CustomRoadsService } from "@/services/CustomRoadsService";
import { RestrictedAreasService } from "@/services/RestrictedAreasService";
import { store } from "@/store";
import { Options, Vue } from "vue-class-component";
import CustomRoads from "./custom-roads/CustomRoads.vue";
import GeneralInformation from "./general-information/GeneralInformation.vue";
import RestrictedAreas from "./restricted-areas/RestrictedAreas.vue";
import RestrictionRoads from "./restriction-roads/RestrictionRoads.vue";
import UploadOverlay from "./upload-overlay/UploadOverlay.vue";
import eventBus from "@/services/EventBus";

@Options({
  components: {
    CustomRoads,
    GeneralInformation,
    RestrictedAreas,
    //RestrictionRoads,
    UploadOverlay,
  },
  props: {},
})
export default class OverlayEdition extends Vue {
  overlay: IOverlay = {} as IOverlay;
  click = false;
  publishTabName = "Publish";

  mounted() {
    this.overlay = { ...store.state.overlay };
    /*if(this.overlay.RoutingServiceVersion == "V8")
      this.publishTabName = "Summary";
    else
      this.publishTabName = "Publish";*/
    RestrictedAreasService.getInstance().filterRestrictedAreasByOverlayId(
      this.overlay.OBJECTID
    );
    CustomRoadsService.getInstance().filterCustomRoadsByOverlayId(
      this.overlay.OBJECTID
    );
    eventBus().emitter.on("isClickableEvent", (data: any) => {
      this.click = data.isClickable;
    });
  }

  closeOverlay() {
    RestrictedAreasService.getInstance().filterRestrictedAreasByOverlayId(null);
    CustomRoadsService.getInstance().filterCustomRoadsByOverlayId(null);
    store.commit("setOverlay", null);
  }

  get isV8() {
    var v8 = true;
    if(this.overlay.RoutingServiceVersion == "V8")
      v8 = false;
    return v8;
  }
}
