
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { ApiService } from "@/services/ApiService";
import {version} from '../../../package.json';
import eventBus from "@/services/EventBus";
import { LoaderService } from "@/services/LoaderService";

@Options({
  props: {},
})
export default class Header extends Vue {
  private appVersion: string = version;
  isEditor = false;
  role = "";
  nbClick = 0;

  mounted() {

    eventBus().emitter.on("isEditorEvent", (data: any) => {
      this.isEditor = data.isEditor;
      if(this.isEditor) {
        this.role = "Role: Editor"
      } else {
          this.role = "Role: Viewer"
      }
    });
  }
  


  cancelLogOut(): void {
    (this.$refs.confirmDeleteARoute as any).active = false;
  }

  async confirmLogOut(): Promise<void> {
    LoaderService.getInstance().showLoader();
    ApiService.getInstance().logout();
    location.reload();
  }

  logOut(){
    this.nbClick += 1;
    if(this.nbClick == 5){
      this.nbClick = 0;
      (this.$refs.confirmLogOut as any).active = true;
    }
  }

}
