
import { MapService } from "@/services/MapService";
import { ApiService } from "@/services/ApiService";
import { Options, prop, Vue } from "vue-class-component";
import { NotificationService } from "@/services/NotificationService";
import FeatureTable from "@arcgis/core/widgets/FeatureTable";
import FieldConfig from "@arcgis/core/widgets/FeatureForm/FieldConfig";
import eventBus from "@/services/EventBus";
import { LoaderService } from "@/services/LoaderService";
import { ILocationType } from "@/interfaces/ILocationTypes";
import { ITransportationPoint } from "@/interfaces/ITransportationPoints";
import { IBadLocation } from "@/interfaces/IBadLocation";
import { LocationTransportationService } from "@/services/LocationTransportationService";
import { ContextService } from "@/services/ContextService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { ConfigService } from "@/services/ConfigService";
import Graphic from "@arcgis/core/Graphic";
import { watch } from "@arcgis/core/core/watchUtils";
import {
  standardizeErrorMsg,
  standardizeCatchErrorMsg,
} from "@/utils/errorManagement";
import { Constants } from "@/services/Constants";
import EsriGeoJSONUtils from "@/utils/EsriGeoJSONUtils";
import Point from "@arcgis/core/geometry/Point";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import FieldColumnConfig from "@arcgis/core/widgets/FeatureTable/FieldColumnConfig";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";

@Options({
  components: {},
  props: {},
})
export default class LocationTransportation extends Vue {
  proxyEventLocations: any[] = [];
  proxyTransportationPoints: any[] = [];
  proxyClusters: any[];
  proxyLocationTypes: any[];
  title = "";
  reportSize = 0;
  locationTableSize = { size: 0 };
  transportationTableSize = { size: 0 };
  importTableSize = { size: 0 };
  assignTableSize = { size: 0 };
  reportTableSize = { size: 0 };
  syncTableSize = { size: 0 };
  locationSelectedItems = { size: 0 };
  transportationSelectedItems = { size: 0 };
  reportSelectedItems = { size: 0 };
  syncSelectedItems = { size: 0 };
  showFT = false;
  allLayers: any;
  locationLayers: any;
  transportationLayers: any;
  tableFieldConfig: FieldConfig[] = [
    {
      name: "UUID",
      label: "UUID",
    } as FieldConfig,
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
    {
      name: "Address",
      label: "Address",
    } as FieldConfig,
    {
      name: "Type",
      label: "Type",
    } as FieldConfig,
  ];
  appendData = true;
  overwriteData = false;
  isEditor = false;
  totalFeatures = 0;
  validation = false;
  report = false;
  wrongTPs = false;
  itemsNotImported: any[] = [];
  itemsImported: any[] = [];
  exportedData = "Locations";
  exportType = "Layer";
  proxyType = "Locations";
  eRebusLocationLayerFields = [
    {
      name: "UUID",
      type: "string",
    },
    {
      name: "Name",
      type: "string",
    },
    {
      name: "Address",
      type: "string",
    },
    {
      name: "TypeName",
      type: "string",
    },
    {
      name: "TypeID",
      type: "string",
    },
    {
      name: "ClusterName",
      type: "string",
    },
    {
      name: "ClusterID",
      type: "string",
    },
    {
      name: "UpdatedAt",
      type: "date",
    },
  ];
  eRebusLocationPopupTemplate = {
    title: "{Name}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "UUID",
            label: "UUID",
          },
          {
            fieldName: "Address",
            label: "Address",
          },
          {
            fieldName: "ClusterName",
            label: "Cluster Name",
          },
          {
            fieldName: "ClusterID",
            label: "Cluster ID",
          },
          {
            fieldName: "TypeName",
            label: "Type Name",
          },
          {
            fieldName: "TypeID",
            label: "Type ID",
          },
        ],
      },
    ],
  };
  eRebusTransportationLayerFields = [
    {
      name: "UUID",
      type: "string",
    },
    {
      name: "Name",
      type: "string",
    },
    {
      name: "Address",
      type: "string",
    },
    {
      name: "ParentLocationID",
      type: "string",
    },
  ];
  eRebusTransportationPopupTemplate = {
    title: "{Name}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "UUID",
            label: "UUID",
          },
          {
            fieldName: "Address",
            label: "Address",
          },
          {
            fieldName: "ParentLocationID",
            label: "Parent Location ID",
          },
        ],
      },
    ],
  };

  async mounted(): Promise<void> {
    const errMsg =
      LocationTransportationService.getInstance().initFeatureTables(
        (this.$refs as any).locationFeatureTableRef,
        (this.$refs as any).transportationFeatureTableRef,
        (this.$refs as any).importFeatureTableRef,
        (this.$refs as any).assignFeatureTableRef,
        (this.$refs as any).reportFeatureTableRef,
        (this.$refs as any).syncFeatureTableRef
      );

    if (!errMsg) {
      this.locationTableSize =
        LocationTransportationService.getInstance().locationTableSize;
      this.transportationTableSize =
        LocationTransportationService.getInstance().transportationTableSize;
      this.importTableSize =
        LocationTransportationService.getInstance().importTableSize;
      this.assignTableSize =
        LocationTransportationService.getInstance().assignTableSize;
      this.reportTableSize =
        LocationTransportationService.getInstance().reportTableSize;
      this.syncTableSize =
        LocationTransportationService.getInstance().syncTableSize;
      this.locationSelectedItems =
        LocationTransportationService.getInstance().locationSelectedItems;
      this.transportationSelectedItems =
        LocationTransportationService.getInstance().transportationSelectedItems;
      this.reportSelectedItems =
        LocationTransportationService.getInstance().reportSelectedItems;
      this.syncSelectedItems =
        LocationTransportationService.getInstance().syncSelectedItems;
      this.isEditor = ContextService.getInstance().isEditor();
      this.allLayers = MapService.getInstance().map.allLayers;
      this.locationLayers = [];
      this.transportationLayers = [];
      this.geteRebusClusters();
      this.geteRebusLocationTypes();
      eventBus().emitter.on("addLayer", () => {
        this.allLayers = MapService.getInstance().map.allLayers;
        this.$forceUpdate();
      });
    } else {
      NotificationService.getInstance().showNotification(
        "Error",
        errMsg,
        "red",
        false,
        Constants.mediumNotificationTimeOut
      );
    }
  }

  async onRemoveLocation(): Promise<void> {
    LoaderService.getInstance().showLoader();
    try {
      await LocationTransportationService.getInstance().deleteSelectedLocation();
    } finally {
      LoaderService.getInstance().hideLoader();
    }
  }

  async onRemoveTransportation(): Promise<void> {
    LoaderService.getInstance().showLoader();
    try {
      await LocationTransportationService.getInstance().deleteSelectedTransportation();
    } finally {
      LoaderService.getInstance().hideLoader();
    }
  }

  async onRemoveBadLocation(): Promise<void> {
    LoaderService.getInstance().showLoader();
    try {
      await LocationTransportationService.getInstance().deleteSelectedBadLocation();
    } finally {
      LoaderService.getInstance().hideLoader();
    }
  }

  async geteRebusData(type: string): Promise<void> {
    let buttonSynchronize: any;

    try {
      if (type.toUpperCase() == "LOCATIONS") {
        buttonSynchronize = this.$refs.locationSyncLocationsERebus;
        buttonSynchronize.loading = true;
        const locations =
          await ApiService.getInstance().getProxyEventLocations();
        this.proxyEventLocations = locations;
      } else if (type.toUpperCase() == "TRANSPORTATIONS") {
        buttonSynchronize = this.$refs.locationSyncTransportationsERebus;
        buttonSynchronize.loading = true;
        const transportations =
          await ApiService.getInstance().getProxyTransportationPoints();
        this.proxyTransportationPoints = transportations;
      } else {
        NotificationService.getInstance().showNotification(
          "Error",
          Constants.errorMessageManageLocationsGeteRebusDataUnknownType +
            (type ? type : "undefined"),
          "red",
          false
        );
      }
    } catch (e) {
      console.log("Erreur fetching erebus Data: ", e);

      if (buttonSynchronize) buttonSynchronize.loading = false;

      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMessageManageLocationsGeteRebusData,
        "red",
        false
      );
    }

    if (buttonSynchronize) buttonSynchronize.loading = false;

    this.proxyType = type;
    this.showSyncModal();
    this.showFT = true;
  }

  findLocationTypeNameByID(uuid: string) {
    let locationTypeName = "";

    if (this.proxyLocationTypes && this.proxyLocationTypes.length) {
      const locationTypes = this.proxyLocationTypes.find(
        (locationTypes: any) => locationTypes.id === uuid
      );
      if (locationTypes)
        locationTypeName = locationTypes.name ? locationTypes.name : "Other";
    }

    return locationTypeName;
  }

  findClusterNameByID(uuid: string) {
    let clusterName = "";

    if (this.proxyClusters && this.proxyClusters.length) {
      const cluster = this.proxyClusters.find(
        (cluster: any) => cluster.id === uuid
      );
      if (cluster) clusterName = cluster.name ? cluster.name : "";
    }

    return clusterName;
  }

  async geteRebusLocationTypes(): Promise<void> {
    try {
      this.proxyLocationTypes =
        await ApiService.getInstance().getProxyLocationTypes();
    } catch (e) {
      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMessageManageLocationsGetLocationTypes,
        "red",
        false
      );
    }
  }

  async geteRebusClusters(): Promise<void> {
    try {
      this.proxyClusters = await ApiService.getInstance().getProxyClusters();
    } catch (e) {
      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMessageManageLocationsGetClusters,
        "red",
        false
      );
    }
  }

  showDeleteModal(): void {
    (this.$refs as any).deleteModal.active = true;
  }

  hideDeleteModal(): void {
    (this.$refs as any).deleteModal.active = false;
  }

  showImportModal(): void {
    this.$forceUpdate();
    (this.$refs as any).importModal.active = true;
  }

  hideImportModal(): void {
    (this.$refs as any).importModal.active = false;
  }

  showOverwriteModal(): void {
    (this.$refs as any).overwriteModal.active = true;
  }

  hideOverwriteModal(): void {
    (this.$refs as any).overwriteModal.active = false;
  }

  showAssignModal(): void {
    this.showAssignFeatureTable();
    (this.$refs as any).assignModal.active = true;
  }

  hideAssignModal(): void {
    this.hideAssignFeatureTable();
    (this.$refs as any).assignModal.active = false;
  }

  showUnassignModal(): void {
    (this.$refs as any).unassignModal.active = true;
  }

  hideUnassignModal(): void {
    (this.$refs as any).unassignModal.active = false;
  }

  showExportModal(): void {
    (this.$refs as any).exportModal.active = true;
  }

  hideExportModal(): void {
    (this.$refs as any).exportModal.active = false;
  }

  showSyncModal(): void {
    this.showSyncFeatureTable();
    (this.$refs as any).syncModal.active = true;
  }

  hideSyncModal(): void {
    this.hideSyncFeatureTable();
    (this.$refs as any).syncModal.active = false;
  }

  showImportFeatureTable(layer: any): void {
    const importFT =
      LocationTransportationService.getInstance().importFeatureTable;
    importFT.layer = layer;
    importFT.visible = true;
  }

  hideImportFeatureTable(): void {
    LocationTransportationService.getInstance().importFeatureTable.visible =
      false;
  }

  showAssignFeatureTable(): void {
    const assignFT =
      LocationTransportationService.getInstance().assignFeatureTable;
    let featureLayer: FeatureLayer;
    featureLayer = this.checkID(featureLayer);
    if (assignFT && !this.wrongTPs) {
      featureLayer.title = "";
      assignFT.layer = featureLayer;
      assignFT.visible = true;
    }
  }

  hideAssignFeatureTable(): void {
    LocationTransportationService.getInstance().assignFeatureTable.visible =
      false;
  }

  showReportFeatureTable(): void {
    LocationTransportationService.getInstance().reportFeatureTable.visible =
      true;
    LocationTransportationService.getInstance().reportFeatureTable.refresh();
  }

  hideReportFeatureTable(): void {
    LocationTransportationService.getInstance().reportFeatureTable.visible =
      false;
  }

  showSyncFeatureTable(): void {
    LocationTransportationService.getInstance().syncFeatureTable.layer =
      this.createLayer();
    LocationTransportationService.getInstance().syncFeatureTable.visible = true;
  }

  hideSyncFeatureTable(): void {
    LocationTransportationService.getInstance().syncFeatureTable.visible =
      false;
  }

  addLayer(layer: any): void {
    this.createManageEventLocationsLayerGroup();
    MapService.getInstance().manageEventLocationsGroupLayer.layers.forEach(
      (l: any) => {
        if (l.title == layer.title)
          MapService.getInstance().manageEventLocationsGroupLayer.remove(l);
      }
    );
    MapService.getInstance().manageEventLocationsGroupLayer.add(layer);
  }

  createManageEventLocationsLayerGroup(): void {
    if (!MapService.getInstance().manageEventLocationsGroupLayer) {
      MapService.getInstance().manageEventLocationsGroupLayer = new GroupLayer({
        title: "Manage Event Locations",
        visible: true,
        visibilityMode: "independent",
        layers: [],
      });
      MapService.getInstance().map.add(
        MapService.getInstance().manageEventLocationsGroupLayer
      );
    }
  }

  createLayer(): any {
    const graphicAttributes = this.createGraphicAttributes(
      this.proxyType == "Locations"
        ? this.proxyEventLocations
        : this.proxyType == "Transportations"
        ? this.proxyTransportationPoints
        : null
    );
    const renderer = this.createRenderer();

    const layer = new FeatureLayer({
      source: graphicAttributes,
      objectIdField: "OBJECTID",
      renderer: renderer,
    });

    if (this.proxyType == "Locations") {
      layer.title = "Locations";
      layer.fields = this.eRebusLocationLayerFields as any;
      layer.popupTemplate = this.eRebusLocationPopupTemplate as any;
    } else if (this.proxyType == "Transportations") {
      layer.title = "Transportation Points";
      layer.fields = this.eRebusTransportationLayerFields as any;
      layer.popupTemplate = this.eRebusTransportationPopupTemplate as any;
    }

    this.addLayer(layer);

    return layer;
  }

  createGraphicAttributes(event: any): Graphic[] {
    let graphicAttributes: Graphic[] = [];
    let point: Point;
    let attributes: any;

    event.forEach((element: any, index: any) => {
      if (element.latitude != null && element.longitude != null) {
        point = new Point({
          latitude: parseFloat(element.latitude),
          longitude: parseFloat(element.longitude),
        });
      } else {
        point = new Point({
          latitude: Constants.latitudeCaseUndefined,
          longitude: Constants.longitudeCaseUndefined,
        });
      }

      if (
        this.proxyType == "Locations" &&
        element.id &&
        element.name &&
        element.location_type &&
        element.cluster &&
        element.address
      ) {
        LocationTransportationService.getInstance().syncFeatureTable.fieldConfigs =
          LocationTransportationService.getInstance()
            .syncLocationsFieldConfig as FieldColumnConfig[];
        attributes = {
          OBJECTID: index + 1,
          UUID: element.id,
          Name: element.name,
          TypeID: element.location_type,
          TypeName: this.findLocationTypeNameByID(element.location_type),
          ClusterID: element.cluster,
          ClusterName: this.findClusterNameByID(element.cluster),
          Address: element.address,
          UpdatedAt: Date.now(),
        };
      } else if (
        this.proxyType == "Transportations" &&
        element.id &&
        element.name &&
        element.address &&
        element.location_id
      ) {
        LocationTransportationService.getInstance().syncFeatureTable.fieldConfigs =
          LocationTransportationService.getInstance()
            .syncTransportationsFieldConfig as FieldColumnConfig[];
        attributes = {
          OBJECTID: index + 1,
          UUID: element.id,
          Name: element.name,
          Address: element.address,
          ParentLocationID: element.location_id,
        };
      }

      const graphic = new Graphic({
        geometry: point,
        attributes: attributes,
      });

      graphicAttributes.push(graphic);
    });

    return graphicAttributes;
  }

  createRenderer(): SimpleRenderer {
    let renderer;

    if (this.proxyType == "Locations") {
      renderer = {
        type: "simple",
        symbol: {
          type: "simple-marker",
          style: "square",
          color: "red",
          size: 7,
          outline: {
            color: "red",
          },
        },
        label: "Locations",
      } as any as SimpleRenderer;
    } else if (this.proxyType == "Transportations") {
      renderer = {
        type: "simple",
        symbol: {
          type: "simple-marker",
          color: "black",
          size: 5,
        },
        label: "Transportation Points",
      } as any as SimpleRenderer;
    }

    return renderer;
  }

  refresheRebusData(): void {
    LocationTransportationService.getInstance().syncFeatureTable.layer =
      this.createLayer();
    LocationTransportationService.getInstance().syncFeatureTable.refresh();
  }

  deleteeRebusData(): void {
    const table = LocationTransportationService.getInstance()
      .syncFeatureTable as any;
    let itemsID: any[] = [];
    (table as any).grid.selectedItems.forEach((item: any) => {
      itemsID.push({
        objectId: item.objectId,
      });
    });
    LocationTransportationService.getInstance()
      .syncFeatureTable.layer.applyEdits({
        deleteFeatures: itemsID,
      })
      .then((results: any) => {
        console.log(results);
      });
    table.refresh();
  }

  checkID(featureLayer: FeatureLayer): FeatureLayer {
    const id = (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid.selectedItems.items[0].feature.attributes.ParentLocationID;
    let check = 0;
    (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid.selectedItems.forEach((element: any) => {
      if (element.feature.attributes.ParentLocationID == id) check++;
    });
    if (
      check ==
      (
        LocationTransportationService.getInstance()
          .transportationFeatureTable as any
      ).grid.selectedItems.length
    ) {
      featureLayer = new FeatureLayer({
        url: ConfigService.getInstance().webmapConfig.attributes[
          ConfigService.getInstance().config.featuresServer.transportationPoints
            .urlField
        ],
        definitionExpression:
          "TypeName = 'Check point' AND ParentLocationID = '" + id + "'",
      });
      this.wrongTPs = false;
    } else {
      this.wrongTPs = true;
    }
    return featureLayer;
  }

  checkLocationFieldValidityOld(
    element: any,
    UUID?: any | null,
    checkParentLocationID?: boolean
  ) {
    if (this.itemsNotImported.includes(element)) return false;

    if (UUID && element.feature.attributes.UUID == UUID) return true;

    if (
      typeof element.feature.attributes.OBJECTID != "number" ||
      typeof element.feature.attributes.UUID != "string" ||
      element.feature.attributes.UUID == ""
    )
      return true;

    if (
      typeof element.feature.attributes.Name != "string" ||
      element.feature.attributes.Name == ""
    )
      return true;

    if (
      typeof element.feature.attributes.Address != "string" ||
      element.feature.attributes.Address == ""
    )
      return true;

    if (
      element.feature.attributes.TypeName &&
      (typeof element.feature.attributes.TypeName != "string" ||
        element.feature.attributes.TypeName == "")
    )
      return true;

    if (
      element.feature.attributes.TypeID &&
      (typeof element.feature.attributes.TypeID != "string" ||
        element.feature.attributes.TypeID == "")
    )
      return true;

    if (
      checkParentLocationID &&
      (typeof element.feature.attributes.ParentLocationID != "string" ||
        element.feature.attributes.ParentLocationID == "")
    )
      return true;

    return false;
  }

  checkLocationFieldValidity(
    element: any,
    UUID?: any | null,
    checkParentLocationID?: boolean
  ) {
    if (this.itemsNotImported.includes(element)) return false;

    if (UUID && element.attributes.UUID == UUID) return true;

    if (
      typeof element.attributes.OBJECTID != "number" ||
      typeof element.attributes.UUID != "string" ||
      element.attributes.UUID == ""
    )
      return true;

    if (
      typeof element.attributes.Name != "string" ||
      element.attributes.Name == ""
    )
      return true;

    if (
      typeof element.attributes.Address != "string" ||
      element.attributes.Address == ""
    )
      return true;

    if (
      element.attributes.TypeName &&
      (typeof element.attributes.TypeName != "string" ||
        element.attributes.TypeName == "")
    )
      return true;

    if (
      element.attributes.TypeID &&
      (typeof element.attributes.TypeID != "string" ||
        element.attributes.TypeID == "")
    )
      return true;

    if (
      checkParentLocationID &&
      (typeof element.attributes.ParentLocationID != "string" ||
        element.attributes.ParentLocationID == "")
    )
      return true;

    return false;
  }

  fillLocation(element: any): ILocationType {
    const location: ILocationType = {
      UUID: element.attributes.UUID,
      Name: element.attributes.Name,
      Address: element.attributes.Address,
      TypeName: element.attributes.Type
        ? element.attributes.Type
        : element.attributes.TypeName
        ? element.attributes.TypeName
        : null,
      TypeID: element.attributes.TypeID,
      ClusterName: element.attributes.ClusterName,
      ClusterID: element.attributes.ClusterID,
      IsOriginDestination: element.IsOriginDestination
        ? element.IsOriginDestination
        : 1,
      LocationValidity: element.LocationValidity ? element.LocationValidity : 0,
      SynchronizationStatus: 0,
      UpdatedAt: Date.now(),
      geometry: element.geometry,
    } as ILocationType;

    return location;
  }

  fillTransportationPoint(element: any): ITransportationPoint {
    const transportation: ITransportationPoint = {
      UUID: element.attributes.UUID,
      Name: element.attributes.Name,
      Address: element.attributes.Address,
      TypeName: element.attributes.Type
        ? element.attributes.Type
        : element.attributes.TypeName
        ? element.attributes.TypeName
        : null,
      ParentLocationName: element.attributes.ParentLocationName,
      ParentLocationID: element.attributes.ParentLocationID,
      CheckPointName: element.attributes.CheckPointName,
      CheckPointID: element.attributes.CheckPointID,
      IsOriginDestination: element.attributes.IsOriginDestination
        ? element.attributes.IsOriginDestination
        : 0,
      LocationValidity: element.LocationValidity ? element.LocationValidity : 0,
      TimePenalty: element.attributes.TimePenalty
        ? element.attributes.TimePenalty
        : 0,
      SynchronizationStatus: 0,
      UpdatedAt: Date.now(),
      geometry: element.geometry,
    } as ITransportationPoint;

    return transportation;
  }

  getLocationID(element: any): any {
    return element.attributes.OBJECTID
      ? element.attributes.OBJECTID
      : element.attributes.ID
      ? element.attributes.ID
      : element.attributes.OID
      ? element.attributes.OID
      : null;
  }

  async pushInObject(element: any): Promise<any> {
    const localID = this.getLocationID(element);
    const other: any[] = [];

    Object.keys(element.attributes).forEach((key: any) => {
      if (
        element.attributes[key] != element.attributes.UUID &&
        element.attributes[key] != element.attributes.Name &&
        element.attributes[key] != element.attributes.Address &&
        element.attributes[key] !=
          (element.attributes.Type
            ? element.attributes.Type
            : element.attributes.TypeName
            ? element.attributes.TypeName
            : null)
      ) {
        other.push(key + " : " + element.attributes[key]);
      }
    });

    const badLocation: IBadLocation = {
      UUID: element.attributes.UUID,
      IDSource: localID,
      Name: element.attributes.Name,
      Address: element.attributes.Address,
      Type: element.attributes.Type
        ? element.attributes.Type
        : element.attributes.TypeName
        ? element.attributes.TypeName
        : null,
      Other: other.join("; "),
      UserName: ContextService.getInstance().user.name,
      ImportedDate: Date.now(),
      Comments: "Failed to import",
      geometry: element.geometry,
    } as IBadLocation;
    await LocationTransportationService.getInstance().addBadLocation(
      badLocation
    );
  }

  async importData(): Promise<void> {
    this.report = false;
    this.itemsImported = [];
    this.itemsNotImported = [];
    const errMsg = "Failed to import data";

    const selectedImportFeatureTableItems =
      await LocationTransportationService.getInstance().selectTableLocation();

    if (
      !selectedImportFeatureTableItems ||
      !selectedImportFeatureTableItems.features ||
      !selectedImportFeatureTableItems.features.length
    ) {
      NotificationService.getInstance().showNotification(
        "Import " + this.title,
        "No item(s) to import from the import table. Please select one",
        "red",
        false
      );
    }

    if (this.title != "Locations" && this.title != "Transportation Points") {
      NotificationService.getInstance().showNotification(
        "Import ",
        this.title + " is not a destination layer.",
        "red",
        false
      );
    }

    if (
      this.appendData == true ||
      (this.overwriteData == true && this.validation)
    ) {
      LoaderService.getInstance().showLoader();

      let locations: any;
      if (this.title == "Locations") {
        locations =
          await LocationTransportationService.getInstance().getCurrentLocation();
      } else {
        locations =
          await LocationTransportationService.getInstance().getCurrentTransportation();
      }

      const locationsID: any[] = [];
      locations.forEach(async (location: any) => {
        locationsID.push(location.OBJECTID);
      });

      try {
        selectedImportFeatureTableItems.features.forEach(
          async (element: any) => {
            locations.forEach(async (location: any) => {
              if (
                this.checkLocationFieldValidity(
                  element,
                  this.appendData == true ? location.UUID : null,
                  this.title == "Locations" ? null : true
                )
              ) {
                this.itemsNotImported.push(element);
                this.pushInObject(element);
              }
            });
            if (!this.itemsNotImported.includes(element)) {
              this.itemsImported.push(element);
              if (this.title == "Locations") {
                const location = this.fillLocation(element);
                await LocationTransportationService.getInstance().addLocation(
                  location
                );
              } else {
                const transportation = this.fillTransportationPoint(element);
                await LocationTransportationService.getInstance().addTransportation(
                  transportation
                );
              }
            }
          }
        );
      } catch (error) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Import " + this.title,
          standardizeCatchErrorMsg(error),
          "red",
          false
        );
      } finally {
        // Delete previous locations if successfull and if overwrite option
        if (
          this.itemsImported.length > 0 &&
          locationsID &&
          locationsID.length &&
          this.overwriteData == true
        ) {
          if (this.title == "Locations") {
            await LocationTransportationService.getInstance().deleteLocation(
              locationsID
            );
          } else {
            await LocationTransportationService.getInstance().deleteTransportation(
              locationsID
            );
          }
        }

        LoaderService.getInstance().hideLoader();

        NotificationService.getInstance().showNotification(
          "Import " + this.title,
          this.itemsImported.length.toString() +
            " item(s) have been successfully added." +
            Constants.strReturnLine +
            this.itemsNotImported.length.toString() +
            " item(s) can't be imported.",
          "green",
          false
        );
      }

      this.validation = false;
      this.overwriteData = false;
      this.appendData = true;
      LocationTransportationService.getInstance().importFeatureTable.clearSelection();
    } else if (
      this.validation == false &&
      this.overwriteData == true &&
      this.appendData == false
    ) {
      this.totalFeatures = selectedImportFeatureTableItems.features.length;
      this.$forceUpdate();
      this.showOverwriteModal();
    }

    LocationTransportationService.getInstance().reportFeatureTable.refresh();
    this.report = true;
  }

  assignCheckPoint(): void {
    const transporationGrid = (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid;
    const assignGrid = (
      LocationTransportationService.getInstance().assignFeatureTable as any
    ).grid;
    if (
      transporationGrid &&
      transporationGrid.selectedItems &&
      transporationGrid.selectedItems.items &&
      assignGrid &&
      assignGrid.selectedItems &&
      assignGrid.selectedItems.items
    ) {
      LoaderService.getInstance().showLoader();
      try {
        const cp = assignGrid.selectedItems.items[0];
        transporationGrid.selectedItems.forEach(async (tp: any) => {
          const transportation = new Graphic({
            attributes: {
              OBJECTID: tp.feature.attributes.OBJECTID,
              CheckPointID: cp.attributes.UUID,
              CheckPointName: cp.attributes.Name,
              UpdatedAt: Date.now(),
            },
          });
          await ApiService.callEsriApplyEdits(
            ConfigService.getInstance().webmapConfig.attributes[
              ConfigService.getInstance().config.featuresServer
                .transportationPoints.urlField
            ],
            [transportation],
            ApiService.editMode.UPDATE
          );
        });
      } catch (error) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Assign checkpoint ",
          standardizeCatchErrorMsg(error),
          "red",
          false
        );
      } finally {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Assign checkpoint ",
          "The checkpoint has been assigned to the transportation point(s).",
          "green",
          false
        );
      }
      LocationTransportationService.getInstance().transportationFeatureTable.refresh();
    }
  }

  unassignCheckPoint(): void {
    const transporationGrid = (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid;
    if (
      transporationGrid &&
      transporationGrid.selectedItems &&
      transporationGrid.selectedItems.items
    ) {
      LoaderService.getInstance().showLoader();
      try {
        transporationGrid.selectedItems.forEach(async (tp: any) => {
          const transportation = new Graphic({
            attributes: {
              OBJECTID: tp.feature.attributes.OBJECTID,
              CheckPointID: "",
              CheckPointName: "",
              UpdatedAt: Date.now(),
            },
          });
          await ApiService.callEsriApplyEdits(
            ConfigService.getInstance().webmapConfig.attributes[
              ConfigService.getInstance().config.featuresServer
                .transportationPoints.urlField
            ],
            [transportation],
            ApiService.editMode.UPDATE
          );
        });
      } catch (error) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Unassign checkpoint ",
          standardizeCatchErrorMsg(error),
          "red",
          false
        );
      } finally {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Unassign checkpoint ",
          "The checkpoint(s) have been removed from the transportation point(s).",
          "green",
          false
        );
      }
      LocationTransportationService.getInstance().transportationFeatureTable.refresh();
    }
  }

  getGeometryFeatureLocation(geometry: any): any {
    const geometryFeature = {
      type: geometry
        ? geometry.type
          ? geometry.type.charAt(0).toUpperCase() + geometry.type.slice(1)
          : "Point"
        : "Point",

      coordinates: [
        geometry
          ? geometry.x
            ? geometry.x
            : Constants.latitudeCaseUndefined
          : Constants.latitudeCaseUndefined,
        geometry
          ? geometry.y
            ? geometry.y
            : Constants.longitudeCaseUndefined
          : Constants.longitudeCaseUndefined,
      ],
    };
    return geometryFeature;
  }

  getLocationAttributes(attribute: any): any {
    const properties = {
      UUID: attribute.UUID ? attribute.UUID : "",
      Name: attribute.Name ? attribute.Name : "",
      Address: attribute.Address ? attribute.Address : "",
      TypeName: attribute.TypeName ? attribute.TypeName : "",
      TypeID: attribute.TypeID ? attribute.TypeID : "",
      ClusterName: attribute.ClusterName ? attribute.ClusterName : "",
      ClusterID: attribute.ClusterID ? attribute.ClusterID : "",
      IsOriginDestination: attribute.IsOriginDestination
        ? attribute.IsOriginDestination
        : 1,
      LocationValidity: attribute.LocationValidity
        ? attribute.LocationValidity
        : 0,
      SynchronizationStatus: attribute.SynchronizationStatus
        ? attribute.SynchronizationStatus
        : 0,
      UpdatedAt: attribute.UpdatedAt,
    };
    return properties;
  }

  getTransportationPointAttributes(attribute: any): any {
    const properties = {
      UUID: attribute.UUID ? attribute.UUID : "",
      Name: attribute.Name ? attribute.Name : "",
      Address: attribute.Address ? attribute.Name : "",
      TypeName: attribute.TypeName ? attribute.TypeName : "",
      ParentLocationName: attribute.ParentLocationName
        ? attribute.ParentLocationName
        : "",
      ParentLocationID: attribute.ParentLocationID
        ? attribute.ParentLocationID
        : "",
      CheckPointName: attribute.CheckPointName ? attribute.CheckPointName : "",
      CheckPointID: attribute.CheckPointID ? attribute.CheckPointID : "",
      IsOriginDestination: attribute.IsOriginDestination
        ? attribute.IsOriginDestination
        : 0,
      LocationValidity: attribute.LocationValidity
        ? attribute.LocationValidity
        : 0,
      SynchronizationStatus: attribute.SynchronizationStatus
        ? attribute.SynchronizationStatus
        : 0,
      TimePenalty: attribute.TimePenalty,
      UpdatedAt: attribute.UpdatedAt,
    };

    return properties;
  }

  geteRebusLocationAttributes(attribute: any): any {
    const properties = {
      UUID: attribute.UUID,
      Name: attribute.Name,
      TypeName: attribute.TypeName,
      TypeID: attribute.TypeID,
      ClusterName: attribute.ClusterName,
      ClusterID: attribute.ClusterID,
      Address: attribute.Address,
      UpdatedAt: attribute.UpdatedAt,
    };
    return properties;
  }

  geteRebusTransportationPointAttributes(attribute: any): any {
    const properties = {
      UUID: attribute.UUID,
      Name: attribute.Name,
      TypeName: this.returnCheckPointTypeName(attribute.Name),
      Address: attribute.Address,
      ParentLocationID: attribute.ParentLocationID,
    };
    return properties;
  }

  getReportAttributes(attribute: any): any {
    const properties = {
      UUID: attribute.UUID,
      IDSource: attribute.IDSource ? attribute.IDSource : "",
      Name: attribute.Name,
      Address: attribute.Address ? attribute.Name : "",
      Type: attribute.Type ? attribute.Type : "",
      Other: attribute.other,
      UserName: attribute.UserName,
      Comments: attribute.Comments,
      ImportedDate: attribute.ImportedDate,
    };
    return properties;
  }

  async exportData(): Promise<void> {
    let layer;
    let features: any[] = [];
    let featureLayer: FeatureLayer;
    let featureTable: FeatureTable;
    let where = "1=1";

    try {
      LoaderService.getInstance().showLoader();

      if (this.exportedData == "Locations") {
        featureLayer =
          LocationTransportationService.getInstance().locationTypesFL;
        featureTable =
          LocationTransportationService.getInstance().locationFeatureTable;
      } else if (this.exportedData == "Transportation Points") {
        featureLayer =
          LocationTransportationService.getInstance().transportationPointsFL;
        featureTable =
          LocationTransportationService.getInstance()
            .transportationFeatureTable;
      } else if (this.exportedData == "Report") {
        featureLayer = LocationTransportationService.getInstance().reportFL;
        featureTable =
          LocationTransportationService.getInstance().reportFeatureTable;
      } else if (this.exportedData == "Synchronize") {
        featureLayer = LocationTransportationService.getInstance()
          .syncFeatureTable.layer as FeatureLayer;
        featureTable =
          LocationTransportationService.getInstance().syncFeatureTable;
      }

      if (this.exportType == "Selected") {
        if ((featureTable as any).grid.selectedItems.length > 0) {
          const table = (featureTable as any).grid.selectedItems.items;
          where = "OBJECTID in ";
          const list: any[] = [];
          table.forEach((feat: any) => {
            list.push(feat.feature.attributes.OBJECTID);
          });
          where += "(" + list.join(", ") + ")";
        } else {
          return;
        }
      }

      const query = featureLayer.createQuery();
      query.where = where;
      query.outFields = ["*"];
      const response = await featureLayer.queryFeatures(query);
      response.features.forEach((f: any) => {
        const geometryFeature = this.getGeometryFeatureLocation(f.geometry);

        if (
          this.exportedData == "Locations" ||
          this.exportedData == "Transportation Points" || this.exportedData == "Report"
        ) {
          const feat = {
            type: "Feature",
            objectId: f.attributes.OBJECTID,
            geometry: geometryFeature,
            properties:
              this.exportedData == "Locations"
                ? this.getLocationAttributes(f.attributes)
                : this.exportedData == "Transportation Points"
                ? this.getTransportationPointAttributes(f.attributes)
                : this.getReportAttributes(f.attributes),
          };
          features.push(feat);
        } else if (this.exportedData == "Synchronize") {
          const feat = {
            type: "Feature",
            objectId: f.attributes.OBJECTID,
            geometry: geometryFeature,
            properties:
              this.proxyType == "Locations"
                ? this.geteRebusLocationAttributes(f.attributes)
                : this.geteRebusTransportationPointAttributes(f.attributes),
          };
          features.push(feat);
        }
      });

      if (
        this.exportType == "Layer" ||
        (this.exportType == "Selected" &&
          (featureTable as any).grid.selectedItems.length > 0)
      ) {
        layer = {
          type: "FeatureCollection",
          crs: {
            type: "name",
            properties: {
              name: "EPSG:4326",
            },
          },
          features: features,
        };

        let name = this.exportedData + " - ";
        name += Date.now().toString();
        var hiddenElement = document.createElement("a");
        var data = new Blob([JSON.stringify(layer)], { type: "text/plain" });
        var url = window.URL.createObjectURL(data);
        hiddenElement.href = url;
        hiddenElement.target = "_blank";
        hiddenElement.download = name + ".geojson";
        hiddenElement.click();
      }
    } catch (error) {
      LoaderService.getInstance().hideLoader();
      NotificationService.getInstance().showNotification(
        "Export " + this.exportType,
        "Can't export the data.",
        "red",
        false
      );
    } finally {
      LoaderService.getInstance().hideLoader();
      if (
        this.exportType == "Selected" &&
        (featureTable as any).grid.selectedItems.length == 0
      ) {
        NotificationService.getInstance().showNotification(
          "Export " + this.exportedData,
          "Impossible to export the data because there is no selected item in the table. Please select at least one item.",
          "red",
          false
        );
      } else {
        NotificationService.getInstance().showNotification(
          "Export " + this.exportedData,
          "The data has been exported successfully.",
          "green",
          false
        );
      }
    }
  }

  returnCheckPointTypeName(name: string): any {
    const standardizeName = name.toUpperCase().replaceAll(" ", "");
    if (standardizeName.includes("CHECKPOINT")) return "Check point";

    if (standardizeName.includes("PARKING")) return "Parking";

    return "Load zone";
  }

  async checkLocations(): Promise<void> {
    let notValid = 0;
    try {
      LoaderService.getInstance().showLoader();
      LocationTransportationService.getInstance().locationFeatureTable.clearSelection();
      const query =
        LocationTransportationService.getInstance().locationTypesFL.createQuery();
      query.where = "1=1";
      query.outFields = ["*"];
      const locations =
        await LocationTransportationService.getInstance().locationTypesFL.queryFeatures(
          query
        );
      locations.features.forEach(async (location: any) => {
        let loc = new Graphic({
          attributes: {
            OBJECTID: location.attributes.OBJECTID,
            ClusterName: "Unknown",
            LocationValidity: 0,
            UpdatedAt: Date.now(),
          },
        });

        if (location.attributes.ClusterID) {
          this.proxyClusters.forEach(async (cluster: any) => {
            if (cluster.id == location.attributes.ClusterID) {
              loc.attributes.ClusterName = cluster.name;
            }
          });
        } else {
          loc.attributes.ClusterName = "";
        }

        let binaire = "000";
        if (
          !location.attributes.UUID ||
          location.attributes.UUID == "" ||
          !location.attributes.TypeID ||
          location.attributes.TypeID == "" ||
          !location.geometry
        ) {
          if (!location.attributes.UUID || location.attributes.UUID == "")
            binaire = this.replaceCharAt(0, binaire, "1");
          if (!location.attributes.TypeID || location.attributes.TypeID == "")
            binaire = this.replaceCharAt(1, binaire, "1");
          if (!location.geometry) binaire = this.replaceCharAt(2, binaire, "1");
          switch (binaire) {
            case "100": {
              notValid++;
              loc.attributes.LocationValidity = 1;
              break;
            }
            case "010": {
              notValid++;
              loc.attributes.LocationValidity = 2;
              break;
            }
            case "001": {
              notValid++;
              loc.attributes.LocationValidity = 3;
              break;
            }
            case "110": {
              notValid++;
              loc.attributes.LocationValidity = 4;
              break;
            }
            case "101": {
              notValid++;
              loc.attributes.LocationValidity = 5;
              break;
            }
            case "011": {
              notValid++;
              loc.attributes.LocationValidity = 6;
              break;
            }
            case "111": {
              notValid++;
              loc.attributes.LocationValidity = 7;
              break;
            }
          }
          LocationTransportationService.getInstance().locationFeatureTable.selectRows(
            location
          );
        } else {
          loc.attributes.LocationValidity = 0;
        }

        await ApiService.callEsriApplyEdits(
          ConfigService.getInstance().webmapConfig.attributes[
            ConfigService.getInstance().config.featuresServer.locationTypes
              .urlField
          ],
          [loc],
          ApiService.editMode.UPDATE
        );
      });
    } catch (error) {
      LoaderService.getInstance().hideLoader();
      NotificationService.getInstance().showNotification(
        "Check locations",
        standardizeCatchErrorMsg(error),
        "red",
        true
      );
    } finally {
      if (notValid == 0) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Check locations",
          "All locations are valid",
          "green",
          false
        );
      } else {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Check locations",
          notValid +
            " issue(s) found in the locations. Please, check the Location Validity field in the table to analyse it or them.",
          "red",
          false
        );
      }
      LocationTransportationService.getInstance().locationFeatureTable.refresh();
    }
  }

  async checkTransportationPoints(): Promise<void> {
    let notValid = 0;
    try {
      LoaderService.getInstance().showLoader();
      LocationTransportationService.getInstance().transportationFeatureTable.clearSelection();
      const query1 =
        LocationTransportationService.getInstance().locationTypesFL.createQuery();
      query1.where = "1=1";
      query1.outFields = ["*"];
      const locations =
        await LocationTransportationService.getInstance().locationTypesFL.queryFeatures(
          query1
        );
      const parentLocationIDs: any[] = [];
      const parentLocations: any[] = [];
      locations.features.forEach((location: any) => {
        if (location.attributes.UUID)
          parentLocationIDs.push(location.attributes.UUID);
        if (location.attributes.UUID && location.attributes.Name)
          parentLocations.push({
            ID: location.attributes.UUID,
            Name: location.attributes.Name,
          });
      });
      const query2 =
        LocationTransportationService.getInstance().transportationPointsFL.createQuery();
      query2.where = "1=1";
      query2.outFields = ["*"];
      const tps =
        await LocationTransportationService.getInstance().transportationPointsFL.queryFeatures(
          query2
        );
      const checkPointIDs: any[] = [];
      const checkPoints: any[] = [];
      tps.features.forEach((tp: any) => {
        if (tp.attributes.UUID) checkPointIDs.push(tp.attributes.UUID);
        if (tp.attributes.UUID && tp.attributes.Name)
          checkPoints.push({
            ID: tp.attributes.UUID,
            Name: tp.attributes.Name,
          });
      });
      tps.features.forEach(async (tp: any) => {
        let tp1 = new Graphic({
          attributes: {
            OBJECTID: tp.attributes.OBJECTID,
            ParentLocationName: tp.attributes.ParentLocationName
              ? tp.attributes.ParentLocationName
              : "",
            CheckPointName: tp.attributes.CheckPointName
              ? tp.attributes.CheckPointName
              : "",
            LocationValidity: 0,
            UpdatedAt: Date.now(),
          },
        });

        if (tp.attributes.ParentLocationID) {
          if (parentLocationIDs.includes(tp.attributes.ParentLocationID)) {
            parentLocations.forEach(async (parent: any) => {
              if (
                parent.ID == tp.attributes.ParentLocationID &&
                parent.Name != tp.attributes.ParentLocationName
              ) {
                tp1.attributes.ParentLocationName = parent.Name;
              }
            });
          } else {
            tp1.attributes.ParentLocationName = "Unknown";
          }
        } else if (tp.attributes.ParentLocationName) {
          tp1.attributes.ParentLocationName = "";
        }

        if (tp.attributes.CheckPointID) {
          if (checkPointIDs.includes(tp.attributes.CheckPointID)) {
            checkPoints.forEach(async (checkpoint: any) => {
              if (
                checkpoint.ID == tp.attributes.CheckPointID &&
                checkpoint.Name != tp.attributes.CheckPointName
              ) {
                tp1.attributes.CheckPointName = checkpoint.Name;
              }
            });
          } else {
            tp1.attributes.CheckPointName = "Unknown";
          }
        } else if (tp.attributes.CheckPointName) {
          tp1.attributes.CheckPointName = "";
        }

        let binaire = "00000";
        if (
          !tp.attributes.UUID ||
          tp.attributes.UUID == "" ||
          !tp.attributes.TypeName ||
          tp.attributes.TypeName == "" ||
          !tp.attributes.ParentLocationID ||
          tp.attributes.ParentLocationID == "" ||
          !parentLocationIDs.includes(tp.attributes.ParentLocationID) ||
          (tp.attributes.CheckPointID &&
            !checkPointIDs.includes(tp.attributes.CheckPointID)) ||
          !tp.geometry
        ) {
          if (!tp.attributes.UUID || tp.attributes.UUID == "")
            binaire = this.replaceCharAt(0, binaire, "1");
          if (!tp.attributes.TypeName || tp.attributes.TypeName == "")
            binaire = this.replaceCharAt(1, binaire, "1");
          if (
            !tp.attributes.ParentLocationID ||
            tp.attributes.ParentLocationID == "" ||
            !parentLocationIDs.includes(tp.attributes.ParentLocationID)
          )
            binaire = this.replaceCharAt(2, binaire, "1");
          if (
            tp.attributes.CheckPointID &&
            !checkPointIDs.includes(tp.attributes.CheckPointID)
          )
            binaire = this.replaceCharAt(3, binaire, "1");
          if (!tp.geometry) binaire = this.replaceCharAt(4, binaire, "1");
          switch (binaire) {
            case "10000": {
              notValid++;
              tp1.attributes.LocationValidity = 1;
              break;
            }
            case "01000": {
              notValid++;
              tp1.attributes.LocationValidity = 2;
              break;
            }
            case "00100": {
              notValid++;
              tp1.attributes.LocationValidity = 3;
              break;
            }
            case "00010": {
              notValid++;
              tp1.attributes.LocationValidity = 4;
              break;
            }
            case "00001": {
              notValid++;
              tp1.attributes.LocationValidity = 5;
              break;
            }
            case "11000": {
              notValid++;
              tp1.attributes.LocationValidity = 6;
              break;
            }
            case "10100": {
              notValid++;
              tp1.attributes.LocationValidity = 7;
              break;
            }
            case "10010": {
              notValid++;
              tp1.attributes.LocationValidity = 8;
              break;
            }
            case "10001": {
              notValid++;
              tp1.attributes.LocationValidity = 9;
              break;
            }
            case "01100": {
              notValid++;
              tp1.attributes.LocationValidity = 10;
              break;
            }
            case "01010": {
              notValid++;
              tp1.attributes.LocationValidity = 11;
              break;
            }
            case "01001": {
              notValid++;
              tp1.attributes.LocationValidity = 12;
              break;
            }
            case "00110": {
              notValid++;
              tp1.attributes.LocationValidity = 13;
              break;
            }
            case "00101": {
              notValid++;
              tp1.attributes.LocationValidity = 14;
              break;
            }
            case "00011": {
              notValid++;
              tp1.attributes.LocationValidity = 15;
              break;
            }
            case "11100": {
              notValid++;
              tp1.attributes.LocationValidity = 16;
              break;
            }
            case "11010": {
              notValid++;
              tp1.attributes.LocationValidity = 17;
              break;
            }
            case "11001": {
              notValid++;
              tp1.attributes.LocationValidity = 18;
              break;
            }
            case "10110": {
              notValid++;
              tp1.attributes.LocationValidity = 19;
              break;
            }
            case "10101": {
              notValid++;
              tp1.attributes.LocationValidity = 20;
              break;
            }
            case "10011": {
              notValid++;
              tp1.attributes.LocationValidity = 21;
              break;
            }
            case "01110": {
              notValid++;
              tp1.attributes.LocationValidity = 22;
              break;
            }
            case "01101": {
              notValid++;
              tp1.attributes.LocationValidity = 23;
              break;
            }
            case "01011": {
              notValid++;
              tp1.attributes.LocationValidity = 24;
              break;
            }
            case "00111": {
              tp1.attributes.LocationValidity = 25;
              break;
            }
            case "11110": {
              tp1.attributes.LocationValidity = 26;
              break;
            }
            case "11101": {
              tp1.attributes.LocationValidity = 27;
              break;
            }
            case "11011": {
              tp1.attributes.LocationValidity = 28;
              break;
            }
            case "10111": {
              tp1.attributes.LocationValidity = 29;
              break;
            }
            case "01111": {
              tp1.attributes.LocationValidity = 30;
              break;
            }
            case "11111": {
              tp1.attributes.LocationValidity = 31;
              break;
            }
          }
          LocationTransportationService.getInstance().transportationFeatureTable.selectRows(
            tp
          );
        } else {
          tp1.attributes.LocationValidity = 0;
        }
        await ApiService.callEsriApplyEdits(
          ConfigService.getInstance().webmapConfig.attributes[
            ConfigService.getInstance().config.featuresServer
              .transportationPoints.urlField
          ],
          [tp1],
          ApiService.editMode.UPDATE
        );
      });
    } catch (error) {
      LoaderService.getInstance().hideLoader();
      NotificationService.getInstance().showNotification(
        "Check transportation points",
        standardizeCatchErrorMsg(error),
        "red",
        false
      );
    } finally {
      if (notValid == 0) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Check transportation points",
          "All transportation points are valid",
          "green",
          false
        );
      } else {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Check transportation points",
          notValid +
            " issue(s) found in the transportation points. Please, check the Location Validity field in the table to analyse it or them.",
          "red",
          false
        );
      }
      LocationTransportationService.getInstance().transportationFeatureTable.refresh();
    }
  }

  replaceCharAt(index: number, original: string, replace: string): string {
    return (
      original.substring(0, index) +
      replace +
      original.substring(index + replace.length)
    );
  }

  checkLocationLayers(): void {
    const fieldsList = ["UUID", "Name", "Address", "TypeID"];
    const badFieldsList = [
      "ParentLocationID",
      "ParentLocationsName",
      "CheckPointID",
      "CheckPointName",
    ];
    let locs: any[] = [];
    this.allLayers.forEach((layer: any) => {
      let check = 0;
      if (layer.geometryType == "point") {
        layer.fields.forEach((element: any) => {
          if (fieldsList.includes(element.name)) check++;
          else if (
            badFieldsList.includes(element.name) ||
            layer.title == "Locations"
          )
            check--;
        });
        if (check == fieldsList.length) locs.push(layer);
      }
    });
    this.locationLayers = locs;
    this.showImportModal();
  }

  checkTransportationLayers(): void {
    const fieldsList = ["UUID", "Name", "Address", "ParentLocationID"];
    const transps: any[] = [];
    this.allLayers.forEach((layer: any) => {
      let check = 0;
      if (layer.geometryType == "point") {
        layer.fields.forEach((element: any) => {
          if (fieldsList.includes(element.name)) check++;
          else if (layer.title == "Transportation Points") check--;
        });
        if (check == fieldsList.length) transps.push(layer);
      }
    });
    this.transportationLayers = transps;
    this.showImportModal();
  }

  get isAssignable() {
    let check = 0;
    (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid.selectedItems.forEach((element: any) => {
      if (
        element &&
        element.feature &&
        element.feature.attributes &&
        element.feature.attributes.TypeName != "Check Point"
      )
        check++;
      else if (
        element &&
        element.attributes &&
        element.attributes.TypeName != "Check Point"
      )
        check++;
    });
    if (
      this.transportationSelectedItems.size > 0 &&
      check == this.transportationSelectedItems.size
    )
      return false;
    else return true;
  }

  get isUnassignable() {
    let check = 0;
    (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid.selectedItems.forEach((tp: any) => {
      if (
        tp &&
        tp.feature &&
        tp.feature.attributes &&
        tp.feature.attributes.CheckPointID &&
        tp.feature.attributes.CheckPointName
      )
        check++;
    });
    if (
      this.transportationSelectedItems.size > 0 &&
      check ==
        (
          LocationTransportationService.getInstance()
            .transportationFeatureTable as any
        ).grid.selectedItems.length
    )
      return false;
    else return true;
  }
}
