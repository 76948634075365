
import { Options, Vue } from "vue-class-component";
import { MapService } from "@/services/MapService";
import { ApiService } from "../../../services/ApiService";
import { watch } from "@vue/runtime-core";
import { watch as watchUtils } from "@arcgis/core/core/watchUtils";
import { LoaderService } from "../../../services/LoaderService";

// import { grep } from "jquery";
import eventBus from "@/services/EventBus";
import {
  RevGeocode,
  RevGeocodeResponse,
  SuggestResponse,
} from "@/interfaces/IRevGeocode";
import { RouteFormValidation } from "./RouteWidgetValidation";
import { IPoisFeature } from "@/interfaces/IPoisFeature";
import axios, { CancelTokenSource } from "axios";
import { ConfigService } from "../../../services/ConfigService";
import Polygon from "@arcgis/core/geometry/Polygon";
import moment from "moment";
import { IStatus } from "@/interfaces/ITravelTime";
import { ViaR } from "../../../model/viaR";
import { ILatLng } from "../../../interfaces/IVia";
import { MapForRouteService } from "../../../services/MapForRouteService";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { lngLatToXY } from "@arcgis/core/geometry/support/webMercatorUtils";
import { number } from "@intlify/core-base";
import { stringifyStyle } from "@vue/shared";
import { MatrixServices } from "../../../services/MatrixServices";
import { CalciteAccordionItem } from "@esri/calcite-components/dist/types/components/calcite-accordion-item/calcite-accordion-item";
import { CalciteSelect } from "@esri/calcite-components/dist/types/components/calcite-select/calcite-select";
import { catchClause, cloneWithoutLoc } from "@babel/types";
import FeatureTable from "@arcgis/core/widgets/FeatureTable";
import FieldConfig from "@arcgis/core/widgets/FeatureForm/FieldConfig";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { ContextService } from "@/services/ContextService";
import { NotificationService } from "@/services/NotificationService";
import { Constants } from "@/services/Constants";
import { IEventLocation } from "@/interfaces/IEventLocation";
import Graphic from "@arcgis/core/Graphic";
import CIMSymbol from "@arcgis/core/symbols/CIMSymbol";

@Options({
  props: { test: number },
})
export default class RouteWidget extends Vue {
  allLayers: any;
  coords: ILatLng;
  parentLayer: GraphicsLayer;
  //color: string;
  matrixService: MatrixServices;
  label: "";
  isHidden = true;
  isClickable = true;
  isError = false;
  error = "";
  adressFrom = "";
  adressTo = "";
  locationFrom: IPoisFeature = null;
  locationTo: IPoisFeature = null;
  locationCheckPoint: IPoisFeature = null;

  coordsFrom: {
    lat: number;
    lng: number;
    uuid: string;
    address: string;
    name: string;
  } = { lat: null, lng: null, uuid: null, address: null, name: null };
  coordsTo: {
    lat: number;
    lng: number;
    uuid: string;
    address: string;
    name: string;
  } = { lat: null, lng: null, uuid: null, address: null, name: null };

  departure = "";
  arrival = "";
  days = "";
  overlays = "";
  mode = "car";
  traffic = "enabled";
  unite = "km";
  distance = "00,00";
  distance2 = "00,00";
  time1 = "00";
  time2 = "00";
  time3 = "00";
  time4 = 0;
  geojsonUrl = "";
  pointFrom: { lat: number; lng: number };
  pointTo: { lat: number; lng: number };
  isDepartureHidden = true;
  isArrivalHidden = true;
  isCoordinatesFrom = true;
  isCoordinatesTo = true;
  isKm = true;
  isMi = false;
  isReverse = false;
  searchRadius = "1600";
  overlaysList: any;
  isOverlayPublished = false;
  maneuvers: any = null;
  FormIsValid = false;
  routeFormValidation = new RouteFormValidation(this);
  geocodeFrom: any[] = [];
  poisFrom: IPoisFeature[] = [];
  geocodeIsLoading = false;
  poisIsLoading = false;
  geocodeTo: any[] = [];
  poisTo: IPoisFeature[] = [];
  activeElement: any = null;
  // inputFromIsFocused = true;
  // inputToIsFocused = false;
  regexpCoordinates =
    /^(?<lat>[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?))\s(?<lng>[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))$/;
  cancelTokenSuggestAddress: CancelTokenSource = null; // to cancel a current query
  cancelTokenPoi: CancelTokenSource = null; // to cancel a current query
  EventExtent: number[];
  dateTimePicker = $("#dateTimePicker");
  updateFromRevGeocode = false;
  isAddingStep: any; // is IHandle
  isAddingStepInfo = false;
  currentStatus: IStatus = null;
  viaPoints: ViaR[] = [];
  viaPointsHtml: ViaR[] = [];
  viaIsDirty = false;
  viaIsRemove = false;
  listIsValid = true;
  colors = { CheckPoint: "#ff0000", via: "#1E90FF" };
  RouteWidgetValidation: RouteWidget;
  mapForRouteService: MapForRouteService;
  changeListener: any = null;
  valueListVia: any = null;

  RouteFormValid: RouteFormValidation;
  viaList: ViaR;
  computingRoute = false;
  greenPinIsClickable = false;
  redPinIsClickable = false;
  allPoisRes: any;

  canSave = false;

  isImportDisable = false;
  featureTable: FeatureTable;
  showFT = false;
  ImportWarning: string[];
  tableFieldConfigPolyline: FieldConfig[] = [
    {
      name: "IdFrom",
      label: "Start Id",
    } as FieldConfig,
    {
      name: "IdTo",
      label: "End Id",
    } as FieldConfig,
    {
      name: "Vias",
      label: "Vias",
    } as FieldConfig,
    {
      name: "Osl",
      label: "Osl",
    } as FieldConfig,
    {
      name: "ClientGroups",
      label: "Client Groups",
    } as FieldConfig,
  ];

  tableFieldConfigPoint: FieldConfig[] = [
    {
      name: "IdFrom",
      label: "Start Id",
    } as FieldConfig,
    {
      name: "IdTo",
      label: "End Id",
    } as FieldConfig,
    {
      name: "Index",
      label: "Index",
    } as FieldConfig,
    {
      name: "Vias",
      label: "Coordinate",
    } as FieldConfig,
    {
      name: "Osl",
      label: "Osl",
    } as FieldConfig,
    {
      name: "ClientGroups",
      label: "Client Groups",
    } as FieldConfig,
  ];
  addVia = false;
  isResultTabVisible = false;
  oslPredefined: any;
  route: any;
  canImport = false;

  beforeCreate(): void {
    this.matrixService = new MatrixServices();
  }

  clear() {
    this.route = null;
    this.greenPinIsClickable = false;
    this.redPinIsClickable = false;
    MapService.getInstance().stopPinLocation();
    this.addVia = false;
    if (this.isAddingStep) {
      this.isAddingStep.remove();
      this.isAddingStep = null;
    }
    this.mapForRouteService.viaPointsMapForRouteService = [];
    this.updateViaAfterMove();
    this.computingRoute = false;
    this.adressFrom = "";
    this.adressTo = "";

    this.cleanCoords("from");
    this.cleanCoords("to");

    this.locationCheckPoint = null;
    this.overlays = "";
    this.isError = false;
    this.isCoordinatesFrom = true;
    this.isCoordinatesTo = true;
    this.searchRadius = "1600";
    const radius = this.$refs["radiusInput"] as any;
    radius.value = "1600";
    const hour = this.$refs["hourSelect"] as any;
    hour.childNodes[1].selected = true;
    this.isDepartureHidden = true;
    this.isArrivalHidden = true;
    this.viaIsRemove = false;
    this.viaPoints = [];
    this.viaPointsHtml = [];
    this.mapForRouteService.removePointsFromGraphic();
    this.mapForRouteService.cleanGroupLayerRoute();
    this.isKm = true;
    this.isMi = false;

    $("#dateTimePicker").data("kendoDateTimePicker").value(new Date());
    $("#dateTimePicker2").data("kendoDateTimePicker").value(new Date());

    (this.$refs["useTraffic"] as any).switched = true;
    (this.$refs["traverseGate"] as any).switched = true;
    (this.$refs["usePrecomputedRoute"] as any).switched = true;
    (this.$refs["useTravelTimeService"] as any).switched = false;
    (this.$refs["useTravelTimeService"] as any).disabled = true;
    (this.$refs["routerVersionV8"] as any).switched = true;
    (this.$refs["useCheckPoint"] as any).switched = true;
    (this.$refs["useSteps"] as any).switched = true;
    (this.$refs["searchLocationsAtEventExtent"] as any).switched = true;

    this.clearResults();
    MapService.getInstance().cleanMap();
  }

  clearResults(): void {
    this.time1 = "00";
    this.time2 = "00";
    this.time3 = "00";
    this.time4 = 0;
    this.distance = "00,00";
    this.distance2 = "00,00";
    this.departure = "";
    this.arrival = "";
    this.maneuvers = null;
    this.viaIsRemove = false;
  }

  reverse(): void {
    const x = this.adressFrom;
    this.adressFrom = this.adressTo;
    this.adressTo = x;

    const y = this.coordsFrom;
    this.coordsFrom = this.coordsTo;
    this.coordsTo = y;

    this.removeCheckPoint();

    this.createPinFromCoord("from");
    this.createPinFromCoord("to");
  }

  erase(fromTo: "from" | "to"): void {
    if (fromTo == "from") {
      this.adressFrom = "";
      this.coordsFrom = {
        lat: null,
        lng: null,
        uuid: null,
        address: null,
        name: null,
      };
      this.isCoordinatesFrom = true;
      MapService.getInstance().cleanPin(MapService.getInstance().graphicFrom);
      this.geocodeFrom = [];
    } else if (fromTo == "to") {
      this.adressTo = "";
      this.coordsTo = {
        lat: null,
        lng: null,
        uuid: null,
        address: null,
        name: null,
      };

      this.removeCheckPoint();

      this.isCoordinatesTo = true;
      MapService.getInstance().cleanPin(MapService.getInstance().graphicTo);
      this.geocodeTo = [];
    }
  }
  addViaPoint(
    coords: ILatLng,
    color: string,
    forceAdd?: number,
    address?: string,
    type?: string,
    name?: string,
    uuid?: string,
    timePenalty?: number
  ): void {
    if ((this.listIsValid && !this.isAddingStepInfo) || forceAdd == 1) {
      this.mapForRouteService.createGraphicLayer();
      const aVia = new ViaR(
        coords,
        this.mapForRouteService.graphicLayerViaPoints,
        this.viaPoints.length + 1 + "",
        color,
        address,
        type,
        name,
        uuid,
        timePenalty
      );

      this.mapForRouteService.viaPointsMapForRouteService.push(aVia);

      this.updateViaAfterMove(aVia);

      if (this.viaPoints.length > 1) {
        this.viaIsRemove = true;
      }

      this.listIsValid = false;
      this.isAddingStep = false;
    }

    this.isAddingStepInfo = true;
    this.listIsValid = true;
  }

  updateViaAfterMove(via?: ViaR) {
    this.viaPoints =
      this.mapForRouteService.viaPointsMapForRouteService.slice();
    this.viaPointsHtml = this.viaPoints.slice();
  }

  getCoordRounded(latOrLng: number): number {
    const rounded = Math.round(latOrLng * 10000) / 10000;
    return rounded;
  }
  /**
    Have to pass by graphic as json to get attributes
   */
  removeViaPoint(viaLabel: ViaR): void {
    const indexViaPoint = this.viaPoints.findIndex(
      (viaPoints) => viaPoints.graphicsAsJson === viaLabel.graphicsAsJson
    );

    const viaToRemove = this.mapForRouteService.viaPointsMapForRouteService[
      indexViaPoint
    ] as ViaR;
    viaToRemove.deleteGraphic();

    this.mapForRouteService.viaPointsMapForRouteService.splice(
      indexViaPoint,
      1
    );

    for (
      let i = indexViaPoint;
      i < this.mapForRouteService.viaPointsMapForRouteService.length;
      i++
    ) {
      const viaToUpdate = this.mapForRouteService.viaPointsMapForRouteService[
        i
      ] as ViaR;
      const index = i + 1;
      viaToUpdate.setLabel(index.toString());
      viaToUpdate.updateCIMSymbolData();
    }

    //this.viaPointUpdateHmlList();
    this.updateViaAfterMove();
    //this.viaPointUpdateHmlList();
  }

  removeCheckPoint(): void {
    for (let i = 0; this.viaPointsHtml && i < this.viaPointsHtml.length; i++) {
      const viaLabel = this.viaPointsHtml[i] as ViaR;
      if (viaLabel.type && this.checkCheckPointType(viaLabel.type)) {
        this.removeViaPoint(viaLabel);
        this.locationCheckPoint = null;
      }
    }
  }

  async deleteAllPoints(): Promise<void> {
    this.viaIsRemove = false;
    this.razViaPoints();
    this.viaIsRemove = false;
  }

  public startAddingStep(): void {
    this.greenPinIsClickable = false;
    this.redPinIsClickable = false;
    MapService.getInstance().stopPinLocation();

    if (!this.isAddingStep) {
      this.mapForRouteService.createGraphicLayer();
      this.addVia = true;
      this.isAddingStepInfo = false;
      this.mapForRouteService.mapService.mapView.container.style.cursor =
        "crosshair";
      this.isAddingStep = this.mapForRouteService.mapService.mapView.on(
        "click",
        (evt) => {
          if (this.addVia) {
            const coord = {
              lat: evt.mapPoint.latitude,
              lng: evt.mapPoint.longitude,
            };
            this.addViaPoint(coord, this.colors.via);
            this.mapForRouteService.mapService.mapView.container.style.cursor =
              "";
            this.addVia = false;
          }
        }
      );
    } else {
      this.isAddingStep.remove();
      this.isAddingStep = null;
      this.addVia = false;
    }
  }

  public forceStopAddingStep(): void {
    if (this.isAddingStep) {
      this.isAddingStep.remove();
      this.isAddingStep = null;
      this.isAddingStepInfo = false;
    }
  }

  addSelectionChange(ref: any): void {
    //console.log("addSelectionChange - 1");

    if (ref) {
      //console.log("addSelectionChange");
      if (this.changeListener) {
        //console.log("removeEventListener");
        this.changeListener.removeEventListener(
          "calciteListOrderChange",
          this.eventListener,
          false
        );
      }
      //console.log("addEventListener");
      this.changeListener = ref.addEventListener(
        "calciteListOrderChange",
        this.eventListener,
        false
      );
      this.valueListVia = ref;
    }
  }
  eventListener(): void {
    this.viaIsDirty = true;
    this.viaIsRemove = true;
  }

  viaPointUpdateHmlList(): void {
    const valueListVia = this.$refs["allSteps"] as any;
    const valuesVia = valueListVia.getElementsByTagName(
      "calcite-value-list-item"
    );

    //console.log("viaPointUpdateHmlList - valuesVia: ", valuesVia);

    let i = 1;
    for (const valueVia of valuesVia) {
      //console.log("viaPointUpdateHmlList - indice - valueVia: ", i, valueVia);

      //console.log("viaPointUpdateHmlList children: ", valueVia.children[0].children[0]);
      /*console.log(
        "viaPointUpdateHmlList children textContent: ",
        valueVia.children[0].children[0].textContent
      );*/
      if (valueVia.value) {
        let valueViaTyped = valueVia.value as ViaR;
        //console.log("viaPointUpdateHmlList - valueViaTyped Label: ", valueViaTyped.label, valueViaTyped.lat, valueViaTyped.lng);

        const viaFound = this.viaPoints.find(
          (via) =>
            via.lat === valueViaTyped.lat && via.lng === valueViaTyped.lng
        );

        if (viaFound) {
          //console.log("viaPointUpdateHmlList - viaPoint Label: ", viaFound.label, viaFound.lat, viaFound.lng);
          valueViaTyped = viaFound;
          valueVia.children[0].children[0].textContent = viaFound.label;
        } else {
          valueVia.children[0].children[0].textContent = i;
        }
      }

      i++;
    }
  }

  viaDrop(): void {
    const allSteps = this.$refs["allSteps"] as HTMLBodyElement;

    /*const valueListVia = this.$refs["allSteps"] as any;
      console.log("viaDrop - valueListVia: ", valueListVia)
      const valuesVia = valueListVia.getElementsByTagName(
        "calcite-value-list-item"
      );

      for (const valueVia of valuesVia) {
        console.log("viaDrop - valueVia: ", valueVia)
        if (valueVia.value) {
          const valueViaTyped = valueVia.value as ViaR;
          console.log("viaDrop - valueViaTyped: ", valueViaTyped)
        }
      }*/

    let oldViaPoints = this.viaPoints;

    this.deleteAllPoints();
    if (this.viaPoints.length > 0) {
      this.viaPoints = [];
    }

    if (this.viaPointsHtml.length > 0) {
      this.viaPointsHtml = [];
    }

    console.log("viaDrop All Steps: ", allSteps);

    var children = allSteps.children;
    for (var i = 0; i < children.length; i++) {
      console.log("viaDrop children: ", children[i]);
      console.log(
        "viaDrop children textContent: ",
        children[i].children[0].children[0]
      );
      console.log(
        "viaDrop children childrentextContent: ",
        children[i].children[0].children[0].textContent
      );

      const indexChildren =
        parseInt(children[i].children[0].children[0].textContent) - 1;

      if (indexChildren < oldViaPoints.length) {
        const coords = {
          lat: oldViaPoints[indexChildren].lat,
          lng: oldViaPoints[indexChildren].lng,
        };

        const aVia = new ViaR(
          coords,
          this.mapForRouteService.graphicLayerViaPoints,
          i + 1 + "",
          oldViaPoints[indexChildren].color,
          oldViaPoints[indexChildren].address,
          oldViaPoints[indexChildren].type,
          oldViaPoints[indexChildren].name,
          oldViaPoints[indexChildren].uuid,
          oldViaPoints[indexChildren].timePenalty
        );
        this.mapForRouteService.viaPointsMapForRouteService.push(aVia);

        // update the index in the point
        children[i].children[0].children[0].textContent = i + 1 + "";
      }
    }

    this.updateViaAfterMove();
    this.$forceUpdate();
  }

  razViaPoints(): void {
    if (this.viaPoints.length > 0) {
      this.mapForRouteService.razViaPoints();
      this.updateViaAfterMove();
      this.$forceUpdate();
    }
  }

  async copy(): Promise<void> {
    navigator.clipboard.writeText("");

    const routeParams = await this.allRoutePlanningDataInClipBoard();

    navigator.clipboard.writeText(routeParams);

    try {
      this.generateExportFile("RouteParamsExport_", routeParams);
    } catch (e) {
      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMessageRouteExportRouteParams,
        "red",
        false
      );
    }
  }

  async allRoutePlanningDataInClipBoard(): Promise<string> {
    let allData = "{";

    // start & end
    allData += '"from":' + JSON.stringify(this.coordsFrom) + ",";
    allData += '"to":' + JSON.stringify(this.coordsTo) + ",";

    // all vias
    allData += '"via":[';
    for (let i = 0; i < this.viaPoints.length; i++) {
      allData +=
        '{"lat":' +
        this.viaPoints[i].coords.lat +
        ',"lng":' +
        this.viaPoints[i].coords.lng +
        ',"type":' +
        JSON.stringify(this.viaPoints[i].type) +
        ',"name":' +
        JSON.stringify(this.viaPoints[i].name) +
        ',"uuid":' +
        JSON.stringify(this.viaPoints[i].uuid) +
        ',"stopDuration":' +
        JSON.stringify(this.viaPoints[i].timePenalty) +
        "}";
      if (i != this.viaPoints.length - 1) {
        allData += ",";
      }
    }
    allData += "],";

    allData +=
      '"overlays":' +
      JSON.stringify($("#multiselect").data("kendoMultiSelect").value()) +
      ",";

    //time departure / arival times
    const startEndTime = this.getStartArrivalTime();
    if (startEndTime && startEndTime.length > 0) {
      allData += '"departure":"' + startEndTime[0] + '",';
      if (startEndTime.length > 1)
        allData += '"arrival":"' + startEndTime[1] + '",';
    }

    //vehicule mode
    allData +=
      '"mode":"fastest;' +
      this.mode +
      ";traffic:" +
      (this.traffic ? "enabled" : "disabled") +
      '",';

    //Traverse Gates
    allData +=
      '"traverseGates":' + (this.$refs["traverseGate"] as any).switched + ",";

    //Use Historical Data
    allData +=
      '"usehistoricaltraveltime":' +
      (this.$refs["usePrecomputedRoute"] as any).switched +
      ",";

    //Use Travel Time Service
    allData +=
      '"usetraveltimeestimateservice":' +
      (this.$refs["useTravelTimeService"] as any).switched +
      ",";

    //geometry
    allData += '"geom":true,';

    // feuille de route
    allData += '"details":true,';

    //request binary route result
    allData += '"routehandle":false,';

    //Version
    const isV8 = (this.$refs["routerVersionV8"] as any).switched;
    allData += '"version":"' + (isV8 ? "v8" : "v7") + '",';

    let avoid;
    avoid = await this.avoidAreas(
      isV8,
      startEndTime && startEndTime.length > 0 ? startEndTime[0] : null,
      startEndTime && startEndTime.length > 1 ? startEndTime[1] : null
    );

    allData +=
      '"avoid":' + (avoid ? JSON.stringify(avoid) : JSON.stringify(null));

    allData += "}";
    return allData;
  }

  pin(startEnd: "start" | "end" | null): void {
    if (this.addVia) {
      this.isAddingStep.remove();
      this.isAddingStep = null;
      this.addVia = false;
    }
    MapService.getInstance().stopPinLocation();
    if (startEnd) {
      if (startEnd == "start") {
        this.greenPinIsClickable = !this.greenPinIsClickable;
        this.redPinIsClickable = false;
      } else if (startEnd == "end") {
        this.redPinIsClickable = !this.redPinIsClickable;
        this.greenPinIsClickable = false;
      }
      MapService.getInstance().pinLocation(startEnd);
    }
  }

  get showReverse(): boolean {
    return this.adressFrom != "" && this.adressTo != "";
  }

  mounted() {
    if (ContextService.getInstance().isEditor()) {
      this.canSave = true;
    }

    const mapService = MapService.getInstance();

    this.mapForRouteService = new MapForRouteService(mapService);

    this.$el.addEventListener("calciteSwitchChange", (evt: any) => {
      this.onSwitchChange(evt.target.name, evt.target.switched);
    });
    this.$el.addEventListener("calciteSelectChange", (evt: any) => {
      this.onSelectChange(evt.target.selectedOption.value);
    });
    this.$el.addEventListener("calciteRadioButtonGroupChange", (evt: any) => {
      this.onRadioButtonGroupChange(evt.detail);
    });
    this.$el.addEventListener("calciteInputInput", (evt: any) => {
      this.onInputChange(evt.detail.value);
    });

    this.initKendo();
    this.pointFrom = MapService.getInstance().pointFrom;
    this.pointTo = MapService.getInstance().pointTo;
    watch(this.pointFrom, () => {
      if (this.pointFrom) {
        this.RevGeocodeLatLng(this.pointFrom.lat, this.pointFrom.lng, "from");
      }
    });
    watch(this.pointTo, () => {
      if (this.pointTo) {
        this.RevGeocodeLatLng(this.pointTo.lat, this.pointTo.lng, "to");
      }
    });
    eventBus().emitter.on("isOverlayEvent", async (data: any) => {
      this.isOverlayPublished = data.isPublished;
      if (this.isOverlayPublished == true) {
        await this.setOverlaysList(false);
        $("#multiselect").data("kendoMultiSelect").setDataSource(null);
        $("#multiselect")
          .data("kendoMultiSelect")
          .setDataSource(this.overlaysList);
      }
    });
    this.mapForRouteService.initRouteWidget(this);
    this.setExtent();
  }

  async setOverlaysList(
    route: boolean,
    vehicle?: string,
    day?: string,
    osl?: string
  ): Promise<void> {
    const orga = await ApiService.getInstance().getCurrentOrg();
    const event = await ApiService.getInstance().getCurrentEvent();
    if (orga && orga.id && event && event.id) {
      const overlays = await ApiService.getInstance().getCustomOverlays(
        vehicle,
        day,
        osl,
        orga.id,
        event.id
      );
      this.overlaysList = [];
      if (route) {
        overlays.data.forEach((element: any) => {
          this.overlaysList.push(element);
        });
      } else {
        if (overlays.data) {
          overlays.data.forEach((element: any) => {
            if (element.overlay.Attributes.PublishedStatus == "2") {
              const name =
                element.overlay.Attributes.PublishedName.split("-")[3];
              this.overlaysList.push({
                realName: element.overlay.Attributes.PublishedName,
                name: name,
              });
            }
          });
        }
      }
    }
  }

  setExtent(): void {
    const rings = ConfigService.getInstance().webmapConfig.geometry.rings[0];
    const polygon = new Polygon({
      hasZ: false,
      hasM: false,
      rings: rings,
      spatialReference: { wkid: 4326 },
    });
    const extent = polygon.extent;
    this.EventExtent = [extent.xmin, extent.ymin, extent.xmax, extent.ymax];
  }

  onSwitchChange(name: string, val: boolean): void {
    if (name == "switchTraffic") {
      if (val == true) {
        this.traffic = "enabled";
      } else {
        this.traffic = "disabled";
      }
    }

    if (name == "switchRouterVersionV8") {
      if (val == false) {
        (this.$refs["useTravelTimeService"] as any).switched = false;
        (this.$refs["useTravelTimeService"] as any).disabled = true;
      } else {
        if ((this.$refs["usePrecomputedRoute"] as any).switched) {
          (this.$refs["useTravelTimeService"] as any).disabled = false;
        }
      }
    }

    if (name == "switchUsePrecomputedRoute") {
      if (val == true) {
        (this.$refs["useTravelTimeService"] as any).switched = false;
        (this.$refs["useTravelTimeService"] as any).disabled = true;
      } else {
        if ((this.$refs["routerVersionV8"] as any).switched) {
          (this.$refs["useTravelTimeService"] as any).disabled = false;
        }
      }
    }
  }

  onSelectChange(val: string): void {
    if (this.isDepartureHidden == false && this.isArrivalHidden == true) {
      this.isDepartureHidden = true;
    } else if (
      this.isArrivalHidden == false &&
      this.isDepartureHidden == true
    ) {
      this.isArrivalHidden = true;
    }
    if (val == "car") {
      this.mode = val;
    } else if (val == "taxi") {
      this.mode = val;
    } else if (val == "bus") {
      this.mode = val;
    } else if (val == "truck") {
      this.mode = val;
    } else if (val == "bicycle") {
      this.mode = val;
    } else if (val == "pedestrian") {
      this.mode = val;
    } else if (val == "now") {
      //pass
    } else if (val == "depAt") {
      this.isDepartureHidden = false;
    } else if (val == "arrAt") {
      this.isArrivalHidden = false;
    } else if (val == "noHours") {
      this.departure = "";
      this.arrival = "";
    }
  }

  onRadioButtonGroupChange(val: string): void {
    if (val == "km") {
      this.unite = "km";
    } else if (val == "mi") {
      this.unite = "mi";
    }
  }

  onInputChange(val: string): void {
    this.searchRadius = val;
  }

  async initKendo(): Promise<void> {
    await this.setOverlaysList(false);
    const vehicleTypes = await ApiService.getInstance().getVehicleTypes();
    const dayLabels = await ApiService.getInstance().getDayLabels();
    const osl = await ApiService.getInstance().getOsl();
    const clients = await ApiService.getInstance().getClients();
    $("#ClientsMultiselect").kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "id",
      dataSource: clients,
    });
    $("#clientsPredefinedMultiselect").kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "id",
      dataSource: clients,
    });

    $("#multiselect").kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "realName",
      dataSource: this.overlaysList,
    });
    $("#vehicleMultiselect").kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "id",
      dataSource: vehicleTypes,
    });
    $("#dayMultiselect").kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "id",
      dataSource: dayLabels,
    });
    $("#oslMultiselect").kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "id",
      dataSource: osl,
    });
    $("#oslPredefinedMultiselect").kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "id",
      dataSource: osl,
    });
    $("#dateTimePicker").kendoDateTimePicker({
      format: "yyyy/MM/dd hh:mm tt",
    });
    $("#dateTimePicker2").kendoDateTimePicker({
      format: "yyyy/MM/dd hh:mm tt",
    });
    if ($("#dateTimePicker2").data("kendoDateTimePicker"))
      $("#dateTimePicker2").data("kendoDateTimePicker").value(new Date());

    if ($("#dateTimePicker").data("kendoDateTimePicker"))
      $("#dateTimePicker").data("kendoDateTimePicker").value(new Date());
  }

  async updateOverlaysList(): Promise<void> {
    const vehicle = $("#vehicleMultiselect")
      .data("kendoMultiSelect")
      .value()
      .join(";");
    const day = $("#dayMultiselect").data("kendoMultiSelect").value().join(";");
    const osl = $("#oslMultiselect").data("kendoMultiSelect").value().join(";");
    await this.setOverlaysList(false, vehicle, day, osl);
    $("#multiselect").data("kendoMultiSelect").setDataSource(null);
    $("#multiselect").data("kendoMultiSelect").setDataSource(this.overlaysList);
    $("#multiselect").data("kendoMultiSelect").value(this.overlaysList);
  }

  async resetOverlaysList(): Promise<void> {
    $("#vehicleMultiselect").data("kendoMultiSelect").value(null);
    $("#dayMultiselect").data("kendoMultiSelect").value(null);
    $("#oslMultiselect").data("kendoMultiSelect").value(null);
    $("#multiselect").data("kendoMultiSelect").value(null);
    await this.setOverlaysList(false);
    $("#multiselect").data("kendoMultiSelect").setDataSource(null);
    $("#multiselect").data("kendoMultiSelect").setDataSource(this.overlaysList);
  }

  toIsoString(date: Date): string {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num: number) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? "0" : "") + norm;
      };

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds()) +
      dif +
      pad(tzo / 60) +
      ":" +
      pad(tzo % 60)
    );
  }

  async RevGeocodeLatLng(
    lat: number,
    lng: number,
    fromTo: "from" | "to"
  ): Promise<void> {
    const address: RevGeocode = await ApiService.getInstance().revGeocode(
      lat,
      lng
    );
    this.updateFromRevGeocode = true;
    if (Array.isArray(address.data) && address.data.length > 0) {
      if (fromTo === "from") {
        this.populateWithRevGeocodeResponse(
          "from",
          this.coordsFrom,
          address.data[0]
        );
        this.greenPinIsClickable = false;
      } else {
        this.populateWithRevGeocodeResponse(
          "to",
          this.coordsTo,
          address.data[0]
        );
        this.redPinIsClickable = false;
      }
    }
    this.validForm();
  }

  populateWithRevGeocodeResponse(
    fromTo: "from" | "to",
    coord: any,
    geocode: RevGeocodeResponse
  ): void {
    if (fromTo === "from") {
      this.adressFrom = geocode.address.label;
      this.coordsFrom.address = geocode.address.label;
    } else {
      this.removeCheckPoint();
      this.adressTo = geocode.address.label;
      this.coordsTo.address = geocode.address.label;
    }

    // x y use access if exists
    if (geocode.access && geocode.access.length > 0) {
      coord.lat = geocode.access[0].lat;
      coord.lng = geocode.access[0].lng;
    } else {
      coord.lat = geocode.position.lat;
      coord.lng = geocode.position.lng;
    }

    coord.uuid = null;
    coord.name = null;
    coord.address = geocode.address.label;

    // add marker to map
    this.createPinFromCoord(fromTo);
  }

  populateWithGeocodeResponse(
    fromTo: "from" | "to",
    coord: any,
    geocode: SuggestResponse
  ): void {
    if (fromTo === "from") {
      this.adressFrom = geocode.title;
    } else {
      this.adressTo = geocode.title;
    }

    // x y use access if exists
    if (geocode.access && geocode.access.length > 0) {
      coord.lat = geocode.access[0].lat;
      coord.lng = geocode.access[0].lng;
    } else if (geocode.position) {
      coord.lat = geocode.position.lat;
      coord.lng = geocode.position.lng;
    }

    coord.uuid = null;
    coord.name = null;
    coord.address = geocode.title;

    // add marker to map
    this.createPinFromCoord(fromTo);
  }

  populateFrom(geocode: SuggestResponse): void {
    this.populateWithGeocodeResponse("from", this.coordsFrom, geocode);
    this.cleanSearchResult();
  }

  populateTo(geocode: SuggestResponse): void {
    this.populateWithGeocodeResponse("to", this.coordsTo, geocode);
    this.cleanSearchResult();
  }

  async populateWithPoiFrom(poi: IPoisFeature) {
    this.populateWithPoiResponse("from", this.coordsFrom, poi);
    this.cleanSearchResult();
  }

  async populateWithTransportationFrom(
    poi: IPoisFeature,
    poiTranspotation: IPoisFeature
  ) {
    this.populateWithPoiResponse("from", this.coordsFrom, poiTranspotation);
    this.cleanSearchResult();
  }

  async populateWithPoiTo(poi: IPoisFeature, subPoi: IPoisFeature) {
    await this.poiCheckPoint(poi);

    this.populateWithPoiResponse("to", this.coordsTo, poi, subPoi);
    this.cleanSearchResult();
  }

  async populateWithTransportationTo(
    poi: IPoisFeature,
    poiTranspotation: IPoisFeature
  ) {
    await this.poiCheckPoint(poi);
    this.populateWithPoiResponse("to", this.coordsTo, poiTranspotation, null);
    this.cleanSearchResult();
  }

  async populateWithPoiResponse(
    fromTo: "from" | "to",
    coord: { lat: number; lng: number },
    subPoi: IPoisFeature,
    poi?: IPoisFeature,
    poiCheckPointFound?: boolean
  ): Promise<void> {
    if (fromTo === "from") {
      if (poi)
        this.adressFrom =
          subPoi.properties.Name + " (" + poi.properties.Name + ")";
      else this.adressFrom = subPoi.properties.Name;
      this.coordsFrom.name = this.adressFrom;
      this.coordsFrom.uuid = subPoi.properties.UUID;
    } else {
      if (poi)
        this.adressTo =
          subPoi.properties.Name + " (" + poi.properties.Name + ")";
      else this.adressTo = subPoi.properties.Name;
      this.coordsTo.uuid = subPoi.properties.UUID;
      this.coordsTo.name = this.adressTo;
    }

    coord.lng = this.getCoordRounded(subPoi.geometry.coordinates[0]);
    coord.lat = this.getCoordRounded(subPoi.geometry.coordinates[1]);

    if (this.coordsFrom.uuid != null && this.coordsTo.uuid != null) {
      this.razViaPoints();

      await this.findViaLocationPoint();

      this.addCheckPointInViaPoint();
    }

    if (this.coordsFrom.uuid == null && this.coordsTo.uuid != null) {
      this.addCheckPointInViaPoint();
    }

    this.createPinFromCoord(fromTo);
  }

  addCheckPointInViaPoint() {
    if (this.locationCheckPoint) {
      let coord = { lat: 0, lng: 0 };
      coord.lng = this.getCoordRounded(
        this.locationCheckPoint.geometry.coordinates[0]
      );
      coord.lat = this.getCoordRounded(
        this.locationCheckPoint.geometry.coordinates[1]
      );

      this.addViaPoint(
        coord,
        this.colors.CheckPoint,
        1,
        this.locationCheckPoint.properties.Address,
        this.locationCheckPoint.properties.TypeName,
        this.locationCheckPoint.properties.Name,
        this.locationCheckPoint.properties.UUID,
        this.locationCheckPoint.properties.TimePenalty
      );
    }
  }

  async findViaLocationPoint() {
    const fromIdSelected: IEventLocation = {
      attributes: {
        OBJECTID: "Unknown",
        UUID: this.coordsFrom.uuid,
        Name: this.coordsFrom.name,
      },
      geometry: { x: 1, y: 1 },
    };

    const toIdSelected: IEventLocation = {
      attributes: {
        OBJECTID: "Unknown",
        UUID: this.coordsTo.uuid,
        Name: this.coordsTo.name,
      },
      geometry: { x: 1, y: 1 },
    };
    const result = await this.matrixService.getATravelDefinition(
      fromIdSelected,
      toIdSelected
    );

    if (result.attributes != null) {
      if (result.attributes.Via != null && result.attributes.Via != "") {
        const vias = result.attributes.Via;
        if (Array.isArray(vias) && vias.length > 0) {
          for (const via of vias) {
            const coords = {
              lat: via[0],
              lng: via[1],
            };
            this.addViaPoint(coords, this.colors.via, 1);
          }
        }
      }

      if (
        result.attributes.OperationalServiceLevels != null &&
        result.attributes.OperationalServiceLevels.length > 0
      ) {
        const arr: any[] = [];
        const osl: any = await ApiService.getInstance().getOsl();
        result.attributes.OperationalServiceLevels.forEach(
          (elementOsl: any) => {
            arr.push(osl.find((element: any) => element.id == elementOsl));
          }
        );
        $("#oslPredefinedMultiselect").data("kendoMultiSelect").value(arr);
      }
      if (
        result.attributes.ClientGroups != null &&
        result.attributes.ClientGroups.length > 0
      ) {
        const arr: any[] = [];
        const osl: any = await ApiService.getInstance().getClients();
        result.attributes.ClientGroups.forEach((elementClient: any) => {
          arr.push(osl.find((element: any) => element.id == elementClient));
        });
        $("#clientsPredefinedMultiselect").data("kendoMultiSelect").value(arr);
      }
    } else {
      const result = await this.matrixService.GenerateATravelDefinition(
        fromIdSelected.attributes.UUID,
        toIdSelected.attributes.UUID
      );
    }
  }

  async saveMatrix() {
    let array: number[][] = [];

    let osl = [];
    if ($("#oslPredefinedMultiselect").data("kendoMultiSelect")) {
      osl = $("#oslPredefinedMultiselect").data("kendoMultiSelect").value();
      $("#oslPredefinedMultiselect").data("kendoMultiSelect").value(null);
    }

    let client = [];
    if ($("#clientsPredefinedMultiselect").data("kendoMultiSelect")) {
      (client = $("#clientsPredefinedMultiselect")
        .data("kendoMultiSelect")
        .value()),
        $("#clientsPredefinedMultiselect").data("kendoMultiSelect").value(null);
    }

    this.viaPoints.forEach((element) => {
      let arr: number[] = [];
      let saveVia = true;
      if (!element.type) {
        saveVia = true;
      } else {
        if (this.checkCheckPointType(element.type)) {
          saveVia = false;
        }
      }

      if (saveVia) {
        arr.push(element.coords.lat);
        arr.push(element.coords.lng);
        array.push(arr);
      }
    });

    const result = await this.matrixService.saveVia(
      this.coordsFrom.uuid,
      this.coordsTo.uuid,
      array,
      osl,
      client
    );

    if (result && result.length > 0) {
      NotificationService.getInstance().showNotification(
        "Save steps",
        Constants.messageODMatrixSaveViaWithSuccess,
        "green",
        false
      );
    }
  }

  checkCheckPointType(checkPointType: string): any {
    if (
      checkPointType.toUpperCase() == "CHECK POINT" ||
      checkPointType.toUpperCase() == "CHECKPOINT"
    ) {
      return true;
    }
    return false;
  }

  cleanSearchResult(): void {
    this.geocodeFrom = [];
    this.poisFrom = [];
    this.geocodeTo = [];
    this.poisTo = [];

    if (this.cancelTokenPoi) {
      this.cancelTokenPoi.cancel("Canceled, a location has been selected");
      this.cancelTokenPoi = null;
    }

    if (this.cancelTokenSuggestAddress) {
      this.cancelTokenSuggestAddress.cancel(
        "Canceled address, a location has been selected"
      );
      this.cancelTokenSuggestAddress = null;
    }
  }

  async addressChange(event: any, fromTo: "from" | "to"): Promise<void> {
    // run geocode query
    let address = this.adressFrom;
    if (fromTo === "to") {
      address = this.adressTo;
    }

    // check if it s coordinate
    const isCoordinate = this.regexpCoordinates.test(address);

    if (isCoordinate) {
      this.geocodeFrom = [];
      this.geocodeTo = [];
      this.setCoord(fromTo, address);
    } else {
      if (address.length > 1) {
        // change from 2 to 1 search only with 2 caracters
        this.getPoisFromSearch(address, fromTo);
        this.getAddressFromSearch(address, fromTo);
      } else {
        // erase two of geocode doesnt matter
        this.cleanSearchResult();
        this.cleanCoords(fromTo);
      }
    }
  }

  async getAddressFromSearch(
    address: string,
    fromTo: "from" | "to"
  ): Promise<void> {
    const lat = MapService.getInstance().mapView.center.latitude;
    const lng = MapService.getInstance().mapView.center.longitude;

    if (this.cancelTokenSuggestAddress) {
      this.cancelTokenSuggestAddress.cancel(
        "Canceled, is searching a new address"
      );
      this.geocodeIsLoading = false;
    }

    this.cancelTokenSuggestAddress = axios.CancelToken.source();

    this.geocodeIsLoading = true;
    const response2 = await ApiService.getInstance().suggest(
      address,
      lat,
      lng,
      5,
      "en",
      this.cancelTokenSuggestAddress
    );

    if (response2) {
      this.geocodeIsLoading = false;
      if (response2.data && Array.isArray(response2.data)) {
        if (fromTo === "from") {
          this.geocodeFrom = response2.data;
        } else {
          this.geocodeTo = response2.data;
        }
      }
    }
  }

  async getPoisFromSearch(
    searchTerm: string,
    fromTo: "from" | "to"
  ): Promise<void> {
    const apiService = ApiService.getInstance();

    // Cancel query if already start
    if (this.cancelTokenPoi) {
      this.cancelTokenPoi.cancel("Canceled, search a new point");
      this.poisIsLoading = false;
    }

    this.poisIsLoading = true;
    this.cancelTokenPoi = axios.CancelToken.source();
    const orga = await ApiService.getInstance().getCurrentOrg();
    const event = await ApiService.getInstance().getCurrentEvent();

    let extentEvent = [-180, -90, 180, 90];
    if ((this.$refs["searchLocationsAtEventExtent"] as any).switched == true) {
      extentEvent = this.EventExtent;
    }

    const res = await apiService.getPois(
      orga.id,
      event.id,
      searchTerm,
      extentEvent,
      this.cancelTokenPoi
    );

    if (this.allPoisRes == null || this.allPoisRes == undefined) {
      // Fetch all locations once
      this.allPoisRes = await this.GetAllPoisRes();
    }

    this.treatPoiResult(res, fromTo);
  }

  treatPoiResult(res: any, fromTo: "from" | "to"): void {
    if (res) {
      let allTransportationPoints;
      if (
        this.allPoisRes &&
        this.allPoisRes.transportationPoints &&
        this.allPoisRes.transportationPoints.features.length
      ) {
        allTransportationPoints = this.allPoisRes.transportationPoints
          .features as IPoisFeature[]; // CAST NOT WORKING
      }
      let allLocations; // CAST NOT WORKING
      if (
        this.allPoisRes &&
        this.allPoisRes.locationTypes &&
        this.allPoisRes.locationTypes.features.length
      ) {
        allLocations = this.allPoisRes.locationTypes.features as IPoisFeature[]; // CAST NOT WORKING
      }

      const locationTypes = res.locationTypes.features as IPoisFeature[]; // CAST NOT WORKING
      const transportationPoints = res.transportationPoints
        .features as IPoisFeature[]; // CAST NOT WORKING

      for (
        let i = 0;
        i < locationTypes.length && allTransportationPoints;
        i++
      ) {
        for (let j = 0; j < allTransportationPoints.length; j++) {
          if (
            allTransportationPoints[j].properties.ParentLocationID ==
            locationTypes[i].properties.UUID
          ) {
            if (locationTypes[i].properties.TransportationPoint) {
              locationTypes[i].properties.TransportationPoint.push(
                allTransportationPoints[j]
              );
            } else {
              locationTypes[i].properties.TransportationPoint = [];
              locationTypes[i].properties.TransportationPoint.push(
                allTransportationPoints[j]
              );
            }
          }
        }
      }

      // Add the TP in the candidate list, if it is not already in
      for (let i = 0; i < transportationPoints.length && allLocations; i++) {
        const locationUUID =
          transportationPoints[i].properties.ParentLocationID;

        let findInAllLocations = allLocations.find(
          (Location) => Location.properties.UUID == locationUUID
        );

        if (findInAllLocations) {
          let findLocationTypes = locationTypes.find(
            (Location) => Location.properties.UUID == locationUUID
          );

          if (findLocationTypes) {
            let tpToAdd = true;
            for (
              let j = 0;
              findLocationTypes.properties.TransportationPoint &&
              j < findLocationTypes.properties.TransportationPoint.length;
              j++
            ) {
              if (
                findLocationTypes.properties.TransportationPoint[j].properties
                  .UUID == transportationPoints[i].properties.UUID
              ) {
                tpToAdd = false;
              }
            }
            if (tpToAdd) {
              if (!findLocationTypes.properties.TransportationPoint) {
                findLocationTypes.properties.TransportationPoint = [];
              }
              findLocationTypes.properties.TransportationPoint.push(
                transportationPoints[i]
              );
            }
          } else {
            findInAllLocations.properties.TransportationPoint = [];
            findInAllLocations.properties.TransportationPoint.push(
              transportationPoints[i]
            );
            locationTypes.push(findInAllLocations);
          }
        } else {
          locationTypes.push(transportationPoints[i]);
        }
      }

      if (fromTo === "from") {
        this.poisFrom = locationTypes;
      } else {
        this.poisTo = locationTypes;
      }
      this.poisIsLoading = false;
    } else {
      // after a cancellation
      this.poisFrom = [];
      this.poisTo = [];
    }
  }

  setCoord(fromTo: "from" | "to", isCoordinate: string): void {
    const res = isCoordinate.match(this.regexpCoordinates);

    if (res.groups && res.groups.lat && res.groups.lng) {
      const lat = parseFloat(res.groups.lat);
      const lng = parseFloat(res.groups.lng);

      this.cleanCoords(fromTo);

      if (fromTo === "from") {
        this.coordsFrom.lat = lat;
        this.coordsFrom.lng = lng;
      } else {
        this.coordsTo.lat = lat;
        this.coordsTo.lng = lng;
      }
      this.createPinFromCoord(fromTo);
    }
  }

  validForm(): void {
    //this.FormIsValid = false;
    const isValid = this.routeFormValidation.isValid();
    this.FormIsValid = isValid;
  }

  changeFocusInputFrom(event: any, fromTo: "from" | "to"): void {
    if (
      event.type === "blur" &&
      event.relatedTarget &&
      event.relatedTarget.classList.contains("select-" + fromTo)
    ) {
      event.preventDefault;
    } else if (event.type === "blur") {
      // empty two geocode is working
      this.cleanSearchResult();
    }
  }

  cleanCoords(fromTo: "from" | "to"): void {
    if (fromTo === "from") {
      this.coordsFrom.lat = null;
      this.coordsFrom.lng = null;
      this.coordsFrom.uuid = null;
      this.coordsFrom.address = null;
      this.coordsFrom.name = null;
    } else {
      this.coordsTo.lat = null;
      this.coordsTo.lng = null;
      this.coordsTo.uuid = null;
      this.coordsTo.address = null;
      this.coordsTo.name = null;

      this.removeCheckPoint();
    }
  }

  createPinFromCoord(fromTo: "from" | "to"): void {
    if (fromTo === "from") {
      MapService.getInstance().cleanPin(MapService.getInstance().graphicFrom);
      MapService.getInstance().drawStartPoint(
        this.coordsFrom.lat,
        this.coordsFrom.lng
      );
    } else {
      MapService.getInstance().cleanPin(MapService.getInstance().graphicTo);
      MapService.getInstance().drawEndPoint(
        this.coordsTo.lat,
        this.coordsTo.lng
      );
    }
  }

  async routeSub(): Promise<void> {
    LoaderService.getInstance().showLoader();

    const isV8 = (this.$refs["routerVersionV8"] as any).switched;
    const usePrecomputedRoute = (this.$refs["usePrecomputedRoute"] as any)
      .switched;
    const useTravelTimeService = (this.$refs["useTravelTimeService"] as any)
      .switched;
    const traverseGate = (this.$refs["traverseGate"] as any).switched;

    ApiService.getInstance().getCurrentEvent();
    ApiService.getInstance().getCurrentOrg();

    // Overlays
    var multiselect = $("#multiselect").data("kendoMultiSelect");
    if (multiselect) {
      this.overlays = multiselect.value();
    } else {
      this.overlays = "";
    }

    // Departure
    var datetimepicker = $("#dateTimePicker").data("kendoDateTimePicker");
    if (datetimepicker) {
      if (datetimepicker.value()) {
        // this.departure = this.toIsoString(datetimepicker.value());
        // this.departure = this.departure.slice(0, 19);
      }
    }
    var datetimepicker2 = $("#dateTimePicker2").data("kendoDateTimePicker");
    if (datetimepicker2) {
      if (datetimepicker2.value()) {
        this.arrival = this.toIsoString(datetimepicker2.value());
        this.arrival = this.arrival.slice(0, 19);
      }
    }

    const viaRouteArray = this.getViaList();

    const startEndTime = this.getStartArrivalTime();

    let avoid: any;
    avoid = await this.avoidAreas(
      isV8,
      startEndTime && startEndTime.length > 0 ? startEndTime[0] : null,
      startEndTime && startEndTime.length > 1 ? startEndTime[1] : null
    );
    try {
      const route = await ApiService.getInstance().route(
        this.coordsFrom,
        this.coordsTo,
        this.mode,
        this.traffic,
        this.overlays,
        useTravelTimeService,
        startEndTime && startEndTime.length > 0 ? startEndTime[0] : null,
        startEndTime && startEndTime.length > 1 ? startEndTime[1] : null,
        traverseGate,
        isV8,
        viaRouteArray,
        usePrecomputedRoute,
        avoid.length > 0 ? avoid : null
      );

      this.computingRoute = true;
      this.routeSubResultAnalyse(route);
      this.route = route;
      this.isResultTabVisible = true;
      const tab = document.querySelector(".tab-Route");
      const ev = new KeyboardEvent("keydown", { key: "Enter", keyCode: 13 });
      tab?.dispatchEvent(ev);
    } catch (e: any) {
      this.isError = true;
      let errorMessage;
      if (e.data) {
        this.isResultTabVisible = true;
        const tab = document.querySelector(".tab-Route");
        const ev = new KeyboardEvent("keydown", { key: "Enter", keyCode: 13 });
        tab?.dispatchEvent(ev);

        if (e.data.notices) {
          this.error =
            "TITLE\n" +
            e.data.notices.title +
            "\n\n" +
            "CODE\n" +
            e.data.notices.code +
            "\n\n" +
            "SEVERITY\n" +
            e.data.notices.severity;
          errorMessage = e.data.notices.title;
          this.computingRoute = true;
          this.routeSubResultAnalyse(e, "critical");
          this.route = e;
          this.isError = true;
        } else {
          this.time2 = "00";
          this.maneuvers = null;
          this.isError = true;

          // Case of bad routing service request
          if (e.data.title && e.data.status && e.data.cause && e.data.code) {
            this.error =
              "TITLE\n" +
              e.data.title +
              "\n\n" +
              "CODE\n" +
              "status: " +
              e.data.status +
              " number: " +
              e.data.code +
              "\n\n" +
              "CAUSE\n" +
              e.data.cause;
          } else {
            // Case of unfeasible route
            if (e.data.title && e.data.severity && e.data.code) {
              this.error =
                "TITLE\n" +
                e.data.title +
                "\n\n" +
                "CODE\n" +
                e.data.code +
                "\n\n" +
                "SEVERITY\n" +
                e.data.severity;
            } else {
              // other errors
              this.error =
                "TITLE\n" + e.data.title
                  ? e.data.title
                  : "Unknow error" + "STATUS\n" + e.data.status
                  ? e.data.status
                  : "Unknown status";
            }
          }
          errorMessage = e.data.title
            ? e.data.title
            : Constants.errorMessageRouteV7;
        }
      } else {
        this.isResultTabVisible = false;
        this.maneuvers = null;
        this.isError = true;
        errorMessage = Constants.errorMessageRouteV7;
      }

      NotificationService.getInstance().showNotification(
        "Error",
        errorMessage,
        "red",
        false,
        Constants.mediumNotificationTimeOut
      );
    }
    LoaderService.getInstance().hideLoader();
  }

  getViaList(): any {
    const viaRouteArray = [];

    const useCheckpoint = (this.$refs["useCheckPoint"] as any).switched;
    const useSteps = (this.$refs["useSteps"] as any).switched;

    for (
      let i = 0;
      i < this.viaPointsHtml.length &&
      (useCheckpoint == true || useSteps == true);
      i++
    ) {
      let coordVia: any;
      if (useCheckpoint == true && useSteps == true) {
        coordVia = this.populateCoordVia(this.viaPointsHtml[i]);
      }

      if (useCheckpoint == false && useSteps == true) {
        if (
          !this.viaPointsHtml[i].type ||
          (this.viaPointsHtml[i].type &&
            !this.checkCheckPointType(this.viaPointsHtml[i].type))
        ) {
          coordVia = this.populateCoordVia(this.viaPointsHtml[i]);
        }
      }

      if (useSteps == false && useCheckpoint == true) {
        if (
          this.viaPointsHtml[i].type &&
          this.checkCheckPointType(this.viaPointsHtml[i].type)
        ) {
          coordVia = this.populateCoordVia(this.viaPointsHtml[i]);
        }
      }

      if (coordVia) {
        viaRouteArray.push({ ...coordVia });
      }
    }

    return viaRouteArray;
  }

  populateCoordVia(via: ViaR): any {
    const coord = {
      type: "point",
      Text: via.name,
      lat: via.lat,
      lng: via.lng,
      stopDuration: via.timePenalty,
    };

    return coord;
  }

  getStartArrivalTime(): any {
    const timeSelected = (this.$refs["hourSelect"] as any).selectedOption.value;

    let startTime: string;
    let arrivalTime: string;
    let lcDateTime;
    if (timeSelected === "noHours") {
      startTime = "any";
    } else if (timeSelected === "now") {
      //startTime = "now";
      startTime = moment.parseZone(new Date()).format("YYYY-MM-DDTHH:mm:ss");
    } else if (timeSelected === "depAt") {
      // set startime at now if needed
      if (!$("#dateTimePicker").data("kendoDateTimePicker").value()) {
        const now = new Date(Date.now());
        $("#dateTimePicker").data("kendoDateTimePicker").value(now);
      }

      lcDateTime = new Date(
        $("#dateTimePicker").data("kendoDateTimePicker").value()
      );

      startTime = moment.parseZone(lcDateTime).format("YYYY-MM-DDTHH:mm:ss");
    } else if (timeSelected === "arrAt") {
      if (!$("#dateTimePicker2").data("kendoDateTimePicker").value()) {
        const now = new Date(Date.now());
        $("#dateTimePicker2").data("kendoDateTimePicker").value(now);
      }

      lcDateTime = new Date(
        $("#dateTimePicker2").data("kendoDateTimePicker").value()
      );

      arrivalTime = moment.parseZone(lcDateTime).format("YYYY-MM-DDTHH:mm:ss");
    }

    return [
      startTime == undefined ? "" : startTime,
      arrivalTime == undefined ? "" : arrivalTime,
    ];
  }

  async avoidAreas(
    isV8: boolean,
    startTime: string,
    arrivalTime: string
  ): Promise<any> {
    const timeSelected = (this.$refs["hourSelect"] as any).selectedOption.value;

    let areas: any[] = [];

    if (isV8 && this.overlays.length != 0) {
      // why we need to fetch the overlay list?
      await this.setOverlaysList(true);

      Object.keys(this.overlays).forEach(async (key: any) => {
        this.overlaysList.forEach((element: any) => {
          if (element.overlay.Attributes.PublishedName == this.overlays[key]) {
            if (
              element.overlay.Attributes.Active == 1 &&
              element.overlay.Attributes.RoutingServiceVersion.includes("V8") &&
              element.restrictedAreas.length > 0
            ) {
              element.restrictedAreas.forEach((area: any) => {
                const mode = (
                  this.mode.charAt(0).toUpperCase() + this.mode.slice(1)
                ).toString();

                if (area.BoundingBox != null) {
                  if (
                    (timeSelected == "now" &&
                      new Date().toISOString() > area.StartDate &&
                      new Date().toISOString() < area.EndDate) ||
                    (startTime &&
                      timeSelected == "depAt" &&
                      area.StartDate < startTime &&
                      area.EndDate > startTime) ||
                    (arrivalTime &&
                      timeSelected == "arrAt" &&
                      area.StartDate < arrivalTime &&
                      area.EndDate > arrivalTime)
                  )
                    if (
                      (mode == Object.keys(area)[4] &&
                        area.Car == "N" &&
                        mode != Object.keys(area)[5] &&
                        mode != Object.keys(area)[6] &&
                        mode != Object.keys(area)[7]) ||
                      (mode != Object.keys(area)[4] &&
                        mode == Object.keys(area)[5] &&
                        area.Bus == "N" &&
                        mode != Object.keys(area)[6] &&
                        mode != Object.keys(area)[7]) ||
                      (mode != Object.keys(area)[4] &&
                        mode != Object.keys(area)[5] &&
                        mode == Object.keys(area)[6] &&
                        area.Truck == "N" &&
                        mode != Object.keys(area)[7]) ||
                      (mode != Object.keys(area)[4] &&
                        mode != Object.keys(area)[5] &&
                        mode != Object.keys(area)[6] &&
                        mode == Object.keys(area)[7] &&
                        area.Pedestrian == "N") ||
                      (mode != Object.keys(area)[4] &&
                        mode != Object.keys(area)[5] &&
                        mode != Object.keys(area)[6] &&
                        mode != Object.keys(area)[7])
                    ) {
                      areas.push(area.BoundingBox);
                    }
                }
              });
            }
          }
        });
      });
    }
    //if (areas.length < 1) return null;
    //else return areas;
    return areas;
  }

  routeSubResultAnalyse(route: any, critical?: string): void {
    const isV8 = (this.$refs["routerVersionV8"] as any).switched;
    if (
      route &&
      (route.request.status == 200 || critical == "critical") &&
      route.data
    ) {
      if (route.data.departure && route.data.arrival) {
        // if V7 router add ":00" to have a standard time
        if (isV8 == false) {
          route.data.departure = route.data.departure + ":00";
          route.data.arrival = route.data.arrival + ":00";
        }
        const departDate = new Date(route.data.departure);
        const arrivalDate = new Date(route.data.arrival);
        this.departure = moment
          .parseZone(route.data.departure)
          .format("hh:mm a YYYY-MM-DD");
        this.arrival = moment
          .parseZone(route.data.arrival)
          .format("hh:mm a YYYY-MM-DD");
      } else {
        this.departure = null;
        this.arrival = null;
      }

      // Format time to display
      this.time4 = route.data.time / 60;
      if (this.time4 >= 1) {
        this.time4 = 1;
      } else if (this.time4 < 1) {
        this.time4 = 0;
      }
      this.time1 = String(route.data.time / 3600);
      this.time3 = String(route.data.time % 3600);
      this.time2 = String(Number(this.time3) / 60);
      this.time1 = String(Math.floor(Number(this.time1)));
      this.time2 = String(Math.round(Number(this.time2)));

      this.distance = String(route.data.distance / 1000);
      this.distance = Number(this.distance).toFixed(2);
      this.distance = String(this.distance);
      this.distance2 = String((route.data.distance / 1000) * 0.6215);
      this.distance2 = Number(this.distance2).toFixed(2);
      this.distance2 = String(this.distance2);
      this.geojsonUrl = window.URL.createObjectURL(
        new Blob([JSON.stringify(route.data.geometry)])
      );
      MapService.getInstance().drawJSON(this.geojsonUrl, critical);

      try {
        let maneuvers = [];
        let viaNumber = 0;

        if (!isV8) {
          let routeV7 = route.data.details.route[0];
          for (let i = 0; i < routeV7.leg.length; i++) {
            for (let p = 0; p < routeV7.leg[i].maneuver.length; p++) {
              if (
                routeV7.leg[i].maneuver[p].instruction.includes("Arrive at") &&
                routeV7.leg[i + 1] != null
              ) {
                routeV7.leg[i].maneuver[p].via = viaNumber;
                viaNumber += 1;
              }
              maneuvers.push(routeV7.leg[i].maneuver[p]);
            }
          }
        } else if (isV8) {
          let routeV8 = route.data.details.routes[0];
          for (let i = 0; i < routeV8.sections.length; i++) {
            for (let p = 0; p < routeV8.sections[i].actions.length; p++) {
              if (
                routeV8.sections[i].actions[p].instruction.includes(
                  "Arrive at"
                ) &&
                routeV8.sections[i + 1] != null
              ) {
                routeV8.sections[i].actions[p].via = viaNumber;
                viaNumber += 1;
              }
              maneuvers.push(routeV8.sections[i].actions[p]);
            }
          }
        }

        this.maneuvers = maneuvers;

        if (!isV8) {
          this.formatManeuvers();
        } else if (isV8) {
          this.formatActions();
        }
      } catch (e) {
        this.maneuvers = null;
      }
      this.isClickable = true;
      this.isError = false;
    }
  }

  formatManeuvers(): void {
    this.maneuvers = this.maneuvers.map((item: any) => {
      let arr;
      let arr2;
      if (item.instruction.includes('<span class="direction">')) {
        //eslint-disable-line
        arr = item.instruction.split('<span class="direction">'); //eslint-disable-line
      } else if (item.instruction.includes('<span class="next-street">')) {
        //eslint-disable-line
        arr = item.instruction.split('<span class="next-street">'); //eslint-disable-line
      } else if (item.instruction.includes('<span class="exit">')) {
        //eslint-disable-line
        arr = item.instruction.split('<span class="exit">'); //eslint-disable-line
      }
      if ((arr[0] + arr[1]).split("</span>")[0] == "Turn right") {
        arr2 = (arr[0] + arr[1]).split("</span>");
      } else if ((arr[0] + arr[1]).split("</span>")[0] == "Keep right") {
        arr2 = (arr[0] + arr[1]).split("</span>");
      } else if ((arr[0] + arr[1]).split("</span>")[0] == "Turn left") {
        arr2 = (arr[0] + arr[1]).split("</span>");
      } else if ((arr[0] + arr[1]).split("</span>")[0] == "Keep left") {
        arr2 = (arr[0] + arr[1]).split("</span>");
      } else if (arr[1].includes("exit from roundabout")) {
        arr2 = arr[1].split("</span>");
        arr2[1] = arr2[1].slice(2, 22);
      } else {
        arr2 = arr[1].split("</span>");
      }
      if (arr2[0] == "destination/stop") {
        item.direction = arr2[0];
        item.instruction = item.instruction.replace(
          new RegExp("<[^>]*>", "g"),
          ""
        );
        item.instruction = item.instruction.slice(0, 9);
        item.instruction = item.instruction + " " + this.adressTo;
      } else if (arr2[1] == "exit from roundabout") {
        item.direction = arr2[1];
        item.instruction = item.instruction.replace(
          new RegExp("<[^>]*>", "g"),
          ""
        );
      } else {
        item.direction = arr2[0];
        item.instruction = item.instruction.replace(
          new RegExp("<[^>]*>", "g"),
          ""
        );
      }
      return item;
    });
  }

  formatActions(): void {
    this.maneuvers = this.maneuvers.map((item: any) => {
      if (item.action == "roundaboutExit") {
        item.direction = "exit from roundabout";
      } else if (item.instruction.includes("Head")) {
        if (
          item.instruction.includes("north") ||
          item.instruction.includes("toward")
        ) {
          item.direction = "North";
        } else if (item.instruction.includes("south")) {
          item.direction = "South";
        } else if (item.instruction.includes("east")) {
          item.direction = "East";
        } else if (item.instruction.includes("west")) {
          item.direction = "West";
        }
      } else if (item.direction == "right") {
        item.direction = "Turn right";
      } else if (item.direction == "left") {
        item.direction = "Turn left";
      }
      return item;
    });
  }

  poiCheckPoint(poi: IPoisFeature) {
    this.removeCheckPoint();

    if (
      this.allPoisRes &&
      this.allPoisRes.transportationPoints &&
      poi.properties.CheckPointID
    ) {
      let AllResArray = this.allPoisRes.transportationPoints
        .features as IPoisFeature[];
      for (let i = 0; i < AllResArray.length; i++) {
        if (
          this.checkCheckPointType(AllResArray[i].properties.TypeName) &&
          AllResArray[i].properties.UUID == poi.properties.CheckPointID
        ) {
          this.locationCheckPoint = AllResArray[i] as IPoisFeature;

          /*let coord = { lat: 0, lng: 0 };
          const forRound = 1000000; // round up to 6 decimal
          coord.lng =
            Math.round(AllResArray[i].geometry.coordinates[0] * forRound) /
            forRound;
          coord.lat =
            Math.round(AllResArray[i].geometry.coordinates[1] * forRound) /
            forRound;

          let poiLng =
            Math.round(poi.geometry.coordinates[0] * forRound) / forRound;
          let poiLat =
            Math.round(poi.geometry.coordinates[1] * forRound) / forRound;
          this.mapForRouteService.mapService.mapView.goTo({
            center: [
              coord.lng + (coord.lng - poiLng),
              coord.lat + (coord.lat - poiLat),
            ],
          });*/
        }
      }
    }
  }

  async GetAllPoisRes() {
    const apiService = ApiService.getInstance();
    const orga = await ApiService.getInstance().getCurrentOrg();
    const event = await ApiService.getInstance().getCurrentEvent();

    let extentEvent = [-180, -90, 180, 90];
    if ((this.$refs["searchLocationsAtEventExtent"] as any).switched == true) {
      extentEvent = this.EventExtent;
    }
    const allPoiRes = await apiService.getPois(
      orga.id,
      event.id,
      "",
      extentEvent,
      this.cancelTokenPoi
    );
    return allPoiRes;
  }

  generateRoadFile() {
    switch ((this.$refs.roadFileTypeSelect as any).selectedOption.value) {
      case "point":
        this.generateRoadFilePoint();
        break;
      case "polyline":
        this.generateRoadFilePolyline();
        break;
    }
  }

  async generateRoadFilePolyline(): Promise<void> {
    (this.$refs.btnGenerateFile as any).loading = true;
    try {
      let via: any[] = [];
      this.viaPoints.forEach((element) => {
        via.push([element.coords.lat, element.coords.lng]);
      });

      var RouteToExport = {
        type: "FeatureCollection",
        geometryType: "polyline",
        crs: {
          type: "name",
          properties: {
            name: "EPSG:4326",
          },
        },
        features: this.route.data.geometry.features,
      };

      RouteToExport.features[0].geometry =
        this.route.data.geometry.features[0].geometry;
      RouteToExport.features[0].properties = {
        From: [this.coordsFrom.lat, this.coordsFrom.lng],
        To: [this.coordsTo.lat, this.coordsTo.lng],

        Arrival: this.route.data.arrival,
        Departure: this.route.data.departure,
        //Details: this.route.data.details,
        Distance: this.route.data.distance,
        BaseDuration: this.route.data.baseDuration,
        Duration: this.route.data.time,
        MaxDuration: this.route.data.maxDuration,
        IdFrom: this.coordsFrom.uuid ? this.coordsFrom.uuid : "",
        NameFrom: this.coordsFrom.name,
        IdTo: this.coordsTo.uuid ? this.coordsTo.uuid : "",
        NameTo: this.coordsTo.name,
        Via: JSON.stringify(via),
        Osl: JSON.stringify(
          $("#oslPredefinedMultiselect").data("kendoMultiSelect").value()
        ),
        ClientGroups: JSON.stringify(
          $("#clientsPredefinedMultiselect").data("kendoMultiSelect").value()
        ),
      };

      this.generateExportFile("RouteExport_", JSON.stringify(RouteToExport));

      (this.$refs.btnGenerateFile as any).loading = false;
    } catch (e) {
      (this.$refs.btnGenerateFile as any).loading = false;
    }
  }

  async generateRoadFilePoint(): Promise<void> {
    (this.$refs.btnGenerateFile as any).loading = true;
    try {
      let feature: any[] = [];
      this.viaPoints.forEach((element, index) => {
        feature.push({
          type: "Feature",
          properties: {
            From: [this.coordsFrom.lat, this.coordsFrom.lng],
            To: [this.coordsTo.lat, this.coordsTo.lng],

            Arrival: this.route.data.arrival,
            Departure: this.route.data.departure,
            //Details: this.route.data.details,
            Distance: this.route.data.distance,
            BaseDuration: this.route.data.baseDuration,
            Duration: this.route.data.time,
            MaxDuration: this.route.data.maxDuration,
            IdFrom: this.coordsFrom.uuid ? this.coordsFrom.uuid : "",
            NameFrom: this.coordsFrom.name,
            NameTo: this.coordsTo.name,
            IdTo: this.coordsTo.uuid ? this.coordsTo.uuid : "",
            Osl: JSON.stringify(
              $("#oslPredefinedMultiselect").data("kendoMultiSelect").value()
            ),
            ClientGroups: JSON.stringify(
              $("#clientsPredefinedMultiselect")
                .data("kendoMultiSelect")
                .value()
            ),
            Via: element.coords.lat + "," + element.coords.lng,
            Index: index + 1,
          },
          geometry: {
            type: "Point",
            coordinates: [element.coords.lng, element.coords.lat],
          },
        });
      });
      var RouteToExport = {
        type: "FeatureCollection",
        features: feature,
      };

      this.generateExportFile(
        "RoutePointExport_",
        JSON.stringify(RouteToExport)
      );

      (this.$refs.btnGenerateFile as any).loading = false;
    } catch (e) {
      (this.$refs.btnGenerateFile as any).loading = false;
    }
  }

  generateExportFile(name: string, contains: any) {
    if (this.coordsFrom.uuid)
      name += this.coordsFrom.name
        ? this.coordsFrom.name
        : this.coordsFrom.address + "_";

    if (this.coordsTo.uuid)
      name += this.coordsTo.name
        ? this.coordsTo.name
        : this.coordsTo.address + "_";

    name += Date.now().toString();

    var hiddenElement = document.createElement("a");
    var data = new Blob([contains], {
      type: "text/plain",
    });
    var url = window.URL.createObjectURL(data);

    hiddenElement.href = url;
    hiddenElement.target = "_blank";
    hiddenElement.download = name + ".geojson";
    hiddenElement.click();
  }

  openSelectFile(): void {
    // simulate click on input
    (this.$refs.inputSelectFile as any).click();
  }

  updateDisplayDir() {
    this.isImportDisable = true;
    const filename = (this.$refs.inputSelectFile as any).value.replace(
      /^.*[\\/]/,
      ""
    );
    (this.$refs.displayDir as any).innerHTML = filename;
  }

  showModal() {
    let layers: any[] = [];
    MapService.getInstance().map.allLayers.forEach((element: any) => {
      if (element.fields != null) {
        let find = false;
        element.fields.forEach((field: any) => {
          if (field.name == "IdFrom" || field.name == "IdTo") {
            find = true;
          }
        });
        if (find) {
          layers.push(element);
        }
      }
    });
    this.allLayers = layers;
    this.$forceUpdate();
    (this.$refs as any).modal.active = true;
  }

  hideModal() {
    (this.$refs as any).modal.active = false;
  }

  hideFeatureTable(): void {
    this.featureTable.visible = false;
  }

  showFeatureTablePolyline(layer: any) {
    if (this.featureTable) {
      this.featureTable.view = MapService.getInstance().mapView;
      this.featureTable.layer = layer;
      this.featureTable.fieldConfigs = this.tableFieldConfigPolyline as any;
      this.featureTable.container = (this.$refs as any).featureTableRef;
      this.featureTable.visible = true;
    } else {
      this.featureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: layer,
        fieldConfigs: this.tableFieldConfigPolyline,
        container: (this.$refs as any).featureTableRef,
      });
      watchUtils(
        (this.featureTable as any).grid,
        "selectedItems.length",
        () => {
          if ((this.featureTable as any).grid.selectedItems.length > 0) {
            this.canImport = true;
          } else {
            this.canImport = false;
          }
        }
      );
    }
  }

  showFeatureTablePoint(layer: any) {
    if (this.featureTable) {
      this.featureTable.view = MapService.getInstance().mapView;
      this.featureTable.layer = layer;
      this.featureTable.fieldConfigs = this.tableFieldConfigPoint as any;
      this.featureTable.container = (this.$refs as any).featureTableRef;
      this.featureTable.visible = true;
    } else {
      this.featureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: layer,
        fieldConfigs: this.tableFieldConfigPoint,
        container: (this.$refs as any).featureTableRef,
      });
      watchUtils(
        (this.featureTable as any).grid,
        "selectedItems.length",
        () => {
          if ((this.featureTable as any).grid.selectedItems.length > 0) {
            this.canImport = true;
          } else {
            this.canImport = false;
          }
        }
      );
    }
  }

  importData() {
    if (this.featureTable.layer.geometryType == "polyline") {
      const PredefinedGrid = (this.featureTable as any).grid;
      if (
        PredefinedGrid &&
        PredefinedGrid.selectedItems &&
        PredefinedGrid.selectedItems.items
      ) {
        PredefinedGrid.selectedItems.items.forEach(async (element: any) => {
          if (
            element.feature.attributes.IdFrom &&
            element.feature.attributes.IdTo &&
            element.feature.attributes.Vias
          ) {
            this.ImportToMatrix(
              element.feature.attributes.IdFrom.toString(),
              element.feature.attributes.IdTo.toString(),
              JSON.parse(element.feature.attributes.Vias),
              JSON.parse(element.feature.attributes.Osl),
              JSON.parse(element.feature.attributes.ClientGroups)
            );
          } else {
            NotificationService.getInstance().showNotification(
              "Error",
              "Some attributes are missing. Please check if there is IdFrom, IdTo and Vias.",
              "red",
              false
            );
          }
        });
      }
    } else if (this.featureTable.layer.geometryType == "point") {
      let alreadyTreat: any[] = [];
      const PredefinedGrid = (this.featureTable as any).grid;
      if (
        PredefinedGrid &&
        PredefinedGrid.selectedItems &&
        PredefinedGrid.selectedItems.items
      ) {
        PredefinedGrid.selectedItems.items.forEach((element: any) => {
          if (
            alreadyTreat.find(
              (x) =>
                x.IdFrom === element.feature.attributes.IdFrom &&
                x.IdTo === element.feature.attributes.IdTo
            ) == undefined
          ) {
            alreadyTreat.push({
              IdFrom: element.feature.attributes.IdFrom,
              IdTo: element.feature.attributes.IdTo,
              Vias: [],
              Osl: element.feature.attributes.Osl,
              ClientGroups: element.feature.attributes.ClientGroups,
            });
          }

          alreadyTreat.find(
            (x) =>
              x.IdFrom === element.feature.attributes.IdFrom &&
              x.IdTo === element.feature.attributes.IdTo
          ).Vias[element.feature.attributes.Index - 1] =
            element.feature.attributes.Vias.split(",");

          alreadyTreat
            .find(
              (x) =>
                x.IdFrom === element.feature.attributes.IdFrom &&
                x.IdTo === element.feature.attributes.IdTo
            )
            .Vias.forEach((via: any, index1: any) => {
              via.forEach((coord: any, index2: any) => {
                alreadyTreat.find(
                  (x) =>
                    x.IdFrom === element.feature.attributes.IdFrom &&
                    x.IdTo === element.feature.attributes.IdTo
                ).Vias[index1][index2] = parseFloat(coord);
              });
            });
        });

        let hasError = false;
        this.ImportWarning = [];

        alreadyTreat.forEach((element) => {
          let arrTmp: any[] = [];
          let arrViaMissing: any[] = [];
          let error = false;
          for (let index = 0; index < element.Vias.length; index++) {
            if (element.Vias[index] != null) {
              arrTmp.push(element.Vias[index]);
            } else {
              arrViaMissing.push(index + 1);
            }
          }
          if (arrTmp.length != element.Vias.length) {
            error = true;
            hasError = true;
            this.ImportWarning.push(
              `error at Depart : ${element.IdFrom.toString()}, Arrive : ${element.IdTo.toString()}, missing via ${JSON.stringify(
                arrViaMissing
              )}`
            );
          }
          element.Vias = arrTmp;
          if (!error || (this.$refs["IgnorWarning"] as any).switched) {
            this.ImportToMatrix(
              element.IdFrom.toString(),
              element.IdTo.toString(),
              element.Vias,
              JSON.parse(element.Osl),
              JSON.parse(element.ClientGroups)
            );
          }
        });

        if (hasError && !(this.$refs["IgnorWarning"] as any).switched) {
          (this.$refs as any).ShowImportWarning.active = true;
        }
      }
    }
  }

  CloseImportWarningModal() {
    (this.$refs as any).ShowImportWarning.active = false;
  }

  ImportToMatrix(
    IdFrom: string,
    IdTo: string,
    Vias: any,
    OperationalServiceLevels?: any[],
    Clients?: any[]
  ) {
    this.matrixService.saveVia(
      IdFrom,
      IdTo,
      Vias,
      OperationalServiceLevels,
      Clients
    );
  }

  showPredefinedModal() {
    (this.$refs as any).predefinedModal.active = true;
  }
  hidePredefinedModal() {
    (this.$refs as any).predefinedModal.active = false;
  }

  getHoursMinutes(time: number) {
    let HoursMinutes = "";
    let minutes = Math.ceil(time / 60);
    let hours = 0;
    while (minutes >= 60) {
      minutes -= 60;
      hours += 1;
    }
    if (hours > 0) {
      HoursMinutes = hours + " h " + minutes + " min";
    } else if (minutes > 0) {
      HoursMinutes = minutes + " min";
    } else {
      HoursMinutes = "1 min";
    }
    return HoursMinutes;
  }

  formatPoiNameAddres(poi: IPoisFeature) {
    let nameAddress = "Undefined";

    if (poi.properties.Name && poi.properties.Name.length > 0)
      nameAddress = poi.properties.Name;
    else nameAddress = poi.properties.UUID;

    if (poi.properties.Address && poi.properties.Address.length > 0)
      nameAddress += " - " + poi.properties.Address;

    return nameAddress;
  }
}
