
import { ContextService } from "@/services/ContextService";
import { Options, Vue } from "vue-class-component";
import { MapService } from "../../services/MapService";

@Options({
  props: {},
})

export default class MapContainer extends Vue {

  
  mounted() {
    const mapService = MapService.getInstance({
      containerElement: this.$refs.mapRef as HTMLDivElement,
    });
    this.$refs.mapRef;
    this.$emit('mounted');
    
    if( ContextService.getInstance().isEditor() == true) {
      mapService.initMatrixWidget();
    }

  }

}
