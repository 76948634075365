
export function secondsToReadable(totalSecondes: number): string {

  const totalMinutes = totalSecondes / 60;


  const seconds = Math.round(totalSecondes % 60);
  const minutes = Math.round(totalMinutes % 60);
  const hours = Math.round(totalMinutes - minutes) / 60;

  let secondsAsText = seconds.toString();
  let minutesAsText = minutes.toString();
  let hoursAsText = hours.toString();

  if (seconds < 10) {
    secondsAsText = 0 + secondsAsText.toString();
  }

  if (minutes < 10) {
    minutesAsText = 0 + minutes.toString();
  }

  if (hours < 10) {
    hoursAsText = 0 + hours.toString();
  }


  const output = hoursAsText + ':' + minutesAsText + ':' + secondsAsText;
  return output;
}


export function timeStringToSeconds(timeStr: string): number {

  if (timeStr.length == 8) {
    const timeStrSplit = timeStr.split(":");
    if (timeStrSplit.length == 3) {
      if ( !isNaN((+timeStrSplit[0])) && (+timeStrSplit[0])>=0 && (+timeStrSplit[0])<=24 ) {
        if ( !isNaN((+timeStrSplit[1])) && (+timeStrSplit[1])>=0 && (+timeStrSplit[1])<=60 ) {
          if ( !isNaN((+timeStrSplit[2])) && (+timeStrSplit[2])>=0 && (+timeStrSplit[2])<=60 ) {
            const seconds = (+timeStrSplit[0]) * 3600 + (+timeStrSplit[1]) * 60 + (+timeStrSplit[2]);
            return seconds;
          }
        }
      }
    }
  }

  return null;
}